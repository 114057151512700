<template>
    
    <div>
        <v-card flat outlined>
            <v-card-title>Content</v-card-title>
            <v-divider></v-divider>
            <v-expansion-panels
                v-model="contentPanel"
                multiple
            >
                <v-expansion-panel
                    v-for="(content, i) in courseDataLocal.sections"
                    :key="i"
                >
                    <v-expansion-panel-header>
                        <div>
                            <h3  class="mb-2">{{ content.name }}</h3>
                            <p class="mb-0">{{ formatDuration(content.duration) }}</p>
                        </div>
                    </v-expansion-panel-header>
                    
                    <v-expansion-panel-content>
                        {{ content.desc }}
                        <v-card flat>
                            <v-list-item
                                v-for="(lecture, id) in content.lectures"
                                :key="id"
                            >
                                <v-checkbox
                                    disabled
                                    input-value="true"
                                >

                                </v-checkbox>
                                <v-list-item-content class="py-1">
                                    <v-list-item-title class="font-weight-bold">
                                        <a v-if="lecture.file != ''" @click="updateSrc(lecture.file)">
                                            {{ lecture.name }}
                                        </a>
                                        <div
                                        v-else>
                                            {{ lecture.name }}
                                        </div>
                                        
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{ formatDuration(lecture.duration*60) }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-card>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                </v-expansion-panels>
        </v-card>
        <v-card class="mt-4" outlined v-if="courseDataLocal.links && courseDataLocal.links.length > 0">
            <v-card-title>
                Important links
            </v-card-title>
            <v-card-text>
                <div
                    v-for="(link, i) in courseDataLocal.links"
                    :key="i"
                    class="mb-2"
                >
                    <v-btn
                        color="primary"
                        width="100%"
                        :href="link.url" target="_blank"
                    >
                        {{ link.name }}
                    </v-btn>
                </div>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { ref, watch, onMounted, computed } from '@vue/composition-api'
import {
    mdiCheck,
    mdiAccountOutline,
    mdiWeb,
    mdiPencilOutline,
    mdiClockOutline,
} from '@mdi/js'

import {formatDuration} from '@core/utils'

export default ({
    props:{
        courseData: {
            type: Object,
        }
    },
    setup(props, { emit }) {

        const contentPanel = ref([0])
        const courseDataLocal = ref({})
        const initCourseData = () => {
            courseDataLocal.value = JSON.parse(JSON.stringify(props.courseData))
            contentPanel.value = [0]
        }

        const updateSrc = (fileSrc) => {
            emit('updateSrc', fileSrc)

        }


        onMounted(() => {
            initCourseData()
        })

        watch(
            () => props.courseData,
            () => {
                initCourseData()
            }
        )

        return {
            courseDataLocal,
            updateSrc,
            formatDuration,
            contentPanel
        }
        
    },
})
</script>
