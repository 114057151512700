<template>
     <v-dialog
        v-model="isQuotaDialogOpen"
        max-width="900"
         @click:outside="$emit('update:is-quota-dialog-open',false)"
    >
        <v-card
            flat
        >
            <v-card-title>
                Quotas
            </v-card-title>
            <v-card-text>
                <v-card>
                    <v-card-text>
                        <v-col
                            cols="12"
                            md="6"
                            sm="6"
                        >
                            <v-autocomplete
                                :items="questionsList"
                                label="Question"
                                dense
                                outlined

                            >

                            </v-autocomplete>
                        </v-col>
                        <v-col
                            cols="12"
                            md="6"
                            sm="6"
                        >
                            <v-text-field
                                label
                                dense
                                outlined
                            >

                            </v-text-field>
                        </v-col>
                    </v-card-text>
                </v-card>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import useSurvey from '../../useSurvey'
export default {
    props: {
        isQuotaDialogOpen: {
            type: Boolean,
            required: true,
        },
        projectData: {
            type: Object,
            required: true
        },
    },
    setup(props, {emit}) {

        const {
            listQuestionLabel
        } = useSurvey()

        const questionsList = ref([])
        // listQuestionLabel(props.projectData.json.pages)

        watch(
            () => props.projectData,
            () => {
                questionsList.value = listQuestionLabel(props.projectData.json.pages)
            }
        )

        return {

            questionsList

        }
    }
}
</script>

<style>

</style>