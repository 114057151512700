<template>
    <div>
        
        <v-card
            flat
        >
            <v-card-title>
                Questionnaire Versions
            </v-card-title>

            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="currentQuestionnaireVersionList"
                    :loading="localLoading"
                    dense
                    hide-default-footer
                    disable-pagination
                >
                
                    <!-- <template v-slot:[`item.compare`]="{item, index}">
                        <v-checkbox
                            v-model="compare"
                            dense
                            hide-details
                            class="shrink ma-0 pa-0"
                            :value="index"
                            :disabled="(compare.length > 1 && compare.indexOf(index) === -1) || !$can('update', 'forsta')"
                        >

                        </v-checkbox>
                    </template> -->

                    <!-- created at -->
                    <template #[`item.created_at`]="{item}">
                        <div class="d-flex flex-column">
                            <span class="text-capitalize text--primary">{{ dateFormat(item.created_at)}}</span>
                        </div>
                    </template>

                    <template v-slot:[`item.approved`]="{item, index}">
                        <v-icon v-if="item.approved" color="success">
                            {{ icons.mdiCheck }}
                        </v-icon>
                        <v-btn v-if="!item.approved && index == currentQuestionnaireVersionList.length-1" 
                            x-small
                            outlined
                            plain
                            @click="approve(item)"
                            >
                            Approve
                        </v-btn>
                    </template>

                    <template #[`item.actions`]="{item}">
                        <v-menu
                            bottom
                            left
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                small
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                            </v-btn>
                            </template>

                            <v-list>
                                <v-list-item
                                    @click="generateClick(item)"
                                    :disabled="!$can('update', 'forsta')"
                                >
                                    <v-list-item-title>
                                    <v-icon
                                        size="20"
                                        class="me-2"
                                    >
                                        {{ icons.mdiWrenchOutline }}
                                    </v-icon>
                                    <span>Generate</span>
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                    @click="downloadQuestionnaireVersion(item.id)"
                                    :disabled="!$can('update', 'forsta')"
                                >
                                    <v-list-item-title>
                                    <v-icon
                                        size="20"
                                        class="me-2"
                                    >
                                        {{ icons.mdiDownloadBoxOutline }}
                                    </v-icon>
                                    <span>Download JSON</span>
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                    @click="downloadWordQuestionnaireVersion(item.id)"
                                    :disabled="!$can('read', 'project')"
                                >
                                    <v-list-item-title>
                                    <v-icon
                                        size="20"
                                        class="me-2"
                                    >
                                        {{ icons.mdiFileWordOutline }}
                                    </v-icon>
                                    <span>Download Word</span>
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                        </template>

                    
                </v-data-table>

            </v-card-text>

        </v-card>
        <v-dialog
            v-model="generateDialog"
            persistent
            max-width="600px"
            >
            <v-card>
                <v-card-title>
                    <span class="text-h5">Questionnaire Generation</span>
                </v-card-title>
                <v-card-text>
                    <small>*Survey.xml is always generated.</small>
                    <v-row>
                    <v-col
                        cols="12"
                        sm="6"
                        md="6"
                    >
                        <v-switch
                            v-model="decipher"
                            inset
                            dense
                            required
                            hide-details
                            :label="`Decipher generation`"
                        ></v-switch>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="6"
                    >
                        <v-switch
                            v-model="translation"
                            inset
                            dense
                            required
                            hide-details
                            :label="`Translation files`"
                        ></v-switch>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="6"
                    >
                        <v-switch
                            v-model="prenumbering"
                            inset
                            dense
                            required
                            hide-details
                            :label="`Prenumbering`"
                        ></v-switch>
                    </v-col>
                    
                    <v-col
                        cols="12"
                        sm="6"
                    >
                    <v-autocomplete
                        v-model="compareTo"
                        :items="projectQuestionnaireVersionList"
                        item-value="id"
                        label="Compare To Version"
                        clearable
                        outlined
                        dense
                        full-width
                        hide-details
                        return-object
                        >
                            <template v-slot:selection="data">
                                V{{ data.item.version }} - {{ data.item.country }}
                            </template>
                            <template v-slot:item="data">
                                <template>
                                    <v-list-item-content>V{{ data.item.version }} - {{ data.item.country }}</v-list-item-content>
                                </template>
                            </template>
                    </v-autocomplete>
                    </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    
                    text
                    @click="closeDialog"
                >
                    Close
                </v-btn>
                <v-btn
                    color="primary"
                    text
                    :loading="generateVersionLoading"
                    @click="generateQuestionnaire()"
                >
                    Generate
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="approveDialog"
        >
            <div class="text-center">
                <v-sheet
                class="px-7 pt-7 pb-4 mx-auto text-center d-inline-block"
                color="blue-grey darken-3"
                dark
                >
                <div class="grey--text text--lighten-1 text-body-2 mb-4">
                    Are you sure you want to approve version V{{approvedVersion.version}}?
                </div>

                <v-btn
                    :disabled="approveLoading"
                    class="ma-1"
                    color="grey"
                    plain
                    @click="approveDialog=false"
                >
                    Cancel
                </v-btn>

                <v-btn
                    :loading="approveLoading"
                    class="ma-1"
                    color="success"
                    plain
                    @click="approvalConfirmed"
                >
                    Yes
                </v-btn>
                </v-sheet>
            </div>

        </v-dialog>
        
    </div>
    
</template>

<script>
import { ref, watch, computed } from '@vue/composition-api'
import useSurvey from '../../useSurvey'
import { isEmpty } from '@core/utils'

import { 
    mdiWrenchOutline,
    mdiDownloadBoxOutline,
    mdiFileWordOutline,
    mdiCodeJson,
    mdiCheck,
    mdiCheckCircle,
    mdiDotsVertical
 } from '@mdi/js'

export default ({
    props: {
        versions: {
            type:Array
        },
        loading: {
            type:Boolean
        },
        questionnaireId:{
            type:String
        }
    },
    setup(props) {

        const headers = ref([
            // {
            //     text:'',
            //     align:'left',
            //     value:'compare',
            //     sortable: false,
            //     width: '1%'
            // },
            {
                text: 'Versions',
                value: 'version',
            },
            { text: 'Date', value: 'created_at' },
            { text: 'Validated By', value: 'created_by' },
            { text: 'Decipher Path', value: 'path' },
            { text: 'Notes', value: 'notes' },
            { text: 'Approved By', value: 'approved_by' },
            { text: 'Approved', value: 'approved' },
            {
                text: 'ACTIONS',
                value: 'actions',
                align: 'center',
                sortable: false
            }
        ])

        
        const compareFrom = ref(null)
        const compareTo = ref({})
        const compare = ref([])

        const localVersions = ref([])
        const localLoading = ref(false)
        const generateDialog = ref(false)
        const decipher = ref(false)
        const translation = ref(false)
        const prenumbering = ref(false)
        const versionSelected = ref({})
        const approveDialog = ref(false)

        const {
            dlVersionLoading,
            generateVersionLoading,
            dlWordVersionLoading,
            downloadQuestionnaireVersion,
            downloadWordQuestionnaireVersion,
            generateQuestionnaireVersion,
            updateVersion
        } = useSurvey()

        const dateFormat = (date) => {
            const d = new Date(date)
            const newDate = d.toISOString().split('T')[0];
            const time = d.toTimeString().split(' ')[0];
            return `${newDate} ${time}`
            
        }


        const currentQuestionnaireVersionList = computed(() => {
            return localVersions.value.filter(version => version.questionnaire == props.questionnaireId)
        })

        const projectQuestionnaireVersionList = computed(() => {
            return localVersions.value.filter(version => version.id != compareFrom.value)
        })

        const generateClick = (version) => {
            versionSelected.value = version
            compareFrom.value=version.id
            generateDialog.value = true
        }

        const generateQuestionnaire = () =>{
            compareTo.value = (compareTo.value == null || compareTo.value.id == undefined) ? '' : compareTo.value.id
            const params = {
                decipher: decipher.value,
                translation: translation.value,
                prenumbering: prenumbering.value,
                compare: compareTo.value
            }
            console.log(params)
            
            generateQuestionnaireVersion(versionSelected.value.id, localVersions.value, params)
            // closeDialog()
        }

        const closeDialog = () => {

            decipher.value = false
            prenumbering.value = false
            translation.value = false
            compareTo.value = {}
            generateDialog.value = false
        }


        watch(
            () => props.versions,
            () => {
                localVersions.value = JSON.parse(JSON.stringify(props.versions))
            }
        )
        watch(
            () => props.loading,
            () => {
                localLoading.value = props.loading
            }
        )
        watch(
            generateVersionLoading,
            (newVal, oldVal) => {
                if (!newVal) closeDialog()
            }
        )

        const approveLoading = ref(false)
        const approvedVersion = ref({})
        const approve = (item) => {
            approveDialog.value = true
            approvedVersion.value = item
        }

        const approvalConfirmed = async () => {
            approveLoading.value = true
            const user = JSON.parse(localStorage.getItem('user'))
            const item = {
                id: approvedVersion.value.id,
                approved: true,
                approved_by:user.azure_ad_id
            }
            const resp = await updateVersion(item, localVersions.value)
            approveLoading.value = false
            approveDialog.value = false
        }

        return {
            headers,
            localVersions,
            localLoading,
            dlVersionLoading,
            generateVersionLoading,
            dlWordVersionLoading,
            downloadQuestionnaireVersion,
            downloadWordQuestionnaireVersion,
            dateFormat,
            compare,
            compareFrom,
            compareTo,
            currentQuestionnaireVersionList,
            projectQuestionnaireVersionList,
            generateClick,
            generateQuestionnaire,
            generateDialog,
            decipher,
            translation,
            prenumbering,
            closeDialog,
            approveDialog,
            approveLoading,
            approve,
            approvedVersion,
            approvalConfirmed,

            icons: {
                mdiWrenchOutline,
                mdiDownloadBoxOutline,
                mdiFileWordOutline,
                mdiCodeJson,
                mdiCheck,
                mdiCheckCircle,
                mdiDotsVertical
            }
        }
        
    },
})
</script>
