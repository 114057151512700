<template>
    
    <div>
        <v-row class="course-detail-panel">
            <v-col cols="12">
                <v-card class="">
                    <v-list-item class="grow">
                        <div class="d-flex flex-column">

                            <v-card-title>
                                {{ courseDataLocal.name }}
                            </v-card-title>
                            <v-card-subtitle v-if="courseDataLocal.category">
                                Category: {{ courseDataLocal.category.name }}
                            </v-card-subtitle>
                        </div>
                        <v-row justify="end" class="mr-4">

                            <v-chip
                            label
                            :color="resolveCourseLevelVariant(courseDataLocal.level)"
                            :class="`v-chip-light-bg ${resolveCourseLevelVariant(courseDataLocal.level)}--text font-weight-medium text-capitalize`"
                            >
                            {{ courseDataLocal.level }}
                        </v-chip>
                        </v-row>
                    </v-list-item>
                    <v-card
                        flat
                        class="mx-4">

                        <v-card-text class="d-flex flex-row">
                            <div class="player" v-if="videoSrc">
                                <video controls width="100%" id='video'>
                                    <source id="videoSrc" type="video/mp4" />
                                    <!-- fallback content here -->
                                </video>
                            </div>
                            <div v-else>
                                <img :src="courseData.image" width="100%">
                            </div>
                        </v-card-text>

                    </v-card>
                    <v-card
                        flat
                        class="ma-4"
                    >
                        <!-- <v-card-title>
                            About this course
                        </v-card-title>
                        <v-card-subtitle>
                            {{ courseDataLocal.objective }}
                        </v-card-subtitle>
                        <v-divider class="mx-5"></v-divider> -->
                        <v-card-title>
                            By the numbers
                        </v-card-title>
                        <v-card-text class="d-flex flex-row">
                            <div class="pr-4">
                                <div class="mb-2">
                                    <v-icon small>
                                        {{ icons.mdiCheck }}
                                    </v-icon>
                                    Skill Level: {{ courseDataLocal.skill_level }}
                                </div>
                                <div class="mb-2" v-if="courseDataLocal.students">
                                    <v-icon small>
                                        {{ icons.mdiAccountOutline }}
                                    </v-icon>
                                    Students: {{ courseDataLocal.students.length }}
                                </div>
                                <div class="mb-2">
                                    <v-icon small>
                                        {{ icons.mdiWeb }}
                                    </v-icon>
                                    Languages: {{ courseLanguages }}
                                </div>
                            </div>
                            <div>
                                <!-- <div class="mb-2">
                                    <v-icon small>
                                        {{ icons.mdiPencilOutline }}
                                    </v-icon>
                                    Sessions: {{ courseDataLocal.sessions }}
                                </div> -->
                                <div class="mb-2">
                                    <v-icon small>
                                        {{ icons.mdiLaptop }}
                                    </v-icon>
                                    Format: {{ courseDataLocal.format }}
                                </div>
                                <div class="mb-2">
                                    <v-icon small>
                                        {{ icons.mdiClockOutline }}
                                    </v-icon>
                                    Duration: {{ formatDuration(courseDataLocal.duration) }}
                                </div>
                                
                            </div>
                        </v-card-text>
                        <v-divider class="mx-5"></v-divider>
                        <v-card-title>
                            Description
                        </v-card-title>
                        <v-card-subtitle>
                            {{ courseDataLocal.desc }}
                        </v-card-subtitle>
                        <v-divider class="mx-5"></v-divider>
                        
                        <v-card-title>
                            Instructors
                        </v-card-title>
                        <v-card-text>
                            <div class="pb-3 pt-2"
                                v-for="(instructor, i) in courseDataLocal.instructors"
                                :key="i"
                            >
                                <v-avatar
                                :color="stringToHslColor(instructor.full_name) || 'primary'"
                                size="40"
                                >
                                
                                    <span class="white--text text">{{getInitialName(instructor.full_name)}}</span>
                                </v-avatar>
                                <div
                                class="d-inline-flex flex-column justify-center ms-3"
                                style="vertical-align:middle"
                                >
                                <span class="text--primary font-weight-semibold mb-n1 text-md">
                                    {{ instructor.full_name }}
                                </span>
                                {{ instructor.job_title }}
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
                    
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { ref, watch, onMounted, computed } from '@vue/composition-api'
import {
    mdiCheck,
    mdiAccountOutline,
    mdiWeb,
    mdiPencilOutline,
    mdiClockOutline,
    mdiLaptop,
} from '@mdi/js'
import { getInitialName, stringToHslColor, formatDuration } from '@core/utils'

export default ({
    props:{
        courseData: {
            type: Object,
        },
        videoSrc: {
            type: String
        }
    },
    setup(props, { emit }) {
        
        
        const courseDataLocal = ref({})
        const initCourseData = () => {
            courseDataLocal.value = JSON.parse(JSON.stringify(props.courseData))
        }

        const localSrc = ref(JSON.parse(JSON.stringify(props.videoSrc)))

        const courseLanguages = computed(() => {
            if (courseDataLocal.value.languages){

                return courseDataLocal.value.languages.map(lang => lang.name).join(", ")
            }
            return ''
        })

        
        const resolveCourseLevelVariant = level => {
            if (level === 'CORE') return 'primary'
            if (level === 'INERMEDIATE') return 'info'
            if (level === 'ADVANCED') return 'accent'
            if (level === 'Qual') return 'success'
            if (level === 'Shopper') return 'warning'
            if (level === 'Innovation') return 'error'

            return 'primary'
        }

        const isVideo = (src) => {
            // Check if the src string ends with a common video file extension
            const videoExtensions = [".mp4", ".webm", ".ogg", ".mov", ".avi"];
            return videoExtensions.some(ext => src.endsWith(ext));
        }



        onMounted(() => {
            initCourseData()
        })

        watch(
            () => props.courseData,
            () => {
                initCourseData()
            }
        )

        watch(
            () =>props.videoSrc,
            () => {

                
                if (isVideo(props.videoSrc)){
                    localSrc.value = JSON.parse(JSON.stringify(props.videoSrc))
                    const source = document.getElementById('videoSrc');
                    const video = document.getElementById('video');
                    if (video != null) video.pause();
                    source.setAttribute('src', localSrc.value)
                        video.load();
                        video.play();
                }
                else {
                    window.open(props.videoSrc, '_blank').focus();
                }

                
            }
        )

        return {
            courseDataLocal,
            courseLanguages,

            resolveCourseLevelVariant,
            getInitialName,
            stringToHslColor,
            formatDuration,
            localSrc,

            icons: {
                mdiCheck,
                mdiAccountOutline,
                mdiWeb,
                mdiPencilOutline,
                mdiClockOutline,
                mdiLaptop,
            }
        }
        
    },
})
</script>
