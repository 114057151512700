<template>
    <v-dialog
        v-model="isDialogVisible"
        max-width="900"
        @click:outside="$emit('update:is-dialog-visible', false)"
    >
        <v-card class="create-app-dialog">
            <v-card-text class="pa-5 pa-sm-10">
                <h5 class="text-h5 text-center mb-3">
                {{selectedElement.text ? `Add ${selectedElement.name} Question`: `Add New Question`}}
                </h5>
                <p class="text-sm text-center mb-6">
                Select your question type and fill out the different fields.<br>
                *If you type a job number or job name and check the search project option then it will search the specific questionnaire.
                <v-progress-linear
                    indeterminate
                    color="primary"
                    :active="elementLoading"
                ></v-progress-linear>
                
                </p>
                <v-form
                        @submit="searchElements($event)"
                        class="pb-4"
                    >
                    <v-checkbox
                        v-model="searchProject"
                        label="Search question from specific project"
                        class="mt-0"
                        hide-details
                    >

                    </v-checkbox>
                    <v-text-field
                        v-model="searchQuery"
                        outlined
                        dense
                        clearable
                        hide-details
                        placeholder="Looking for a specific question?"
                        :append-icon="icons.mdiSendOutline"            
                        @click:append="searchElements"
                        @click:append-outer="searchElements"
                        @click:clear="clearSearch"
                    >
                    </v-text-field>
                </v-form>
                <v-row v-if="!searchProject">
                    <v-col
                        cols="12"
                        sm="5"
                        md="4"
                        lg="3"
                    >
                        <app-stepper
                            :current-step.sync="currentStep"
                            :is-active-step-valid="isCurrentStepValid"
                            direction="vertical"
                            :items="createElement"
                            icon-size="24"
                            class="stepper-icon-step-bg"
                        >

                        </app-stepper>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="7"
                        md="8"
                        lg="9"
                    >
                        <v-window
                            v-model="currentStep"
                            class="disable-tab-transition stepper-content pa-4"
                        >
                            <v-window-item >
                                <v-row
                                    dense
                                >
                                    <v-col
                                        v-for="(element, index) in localElements"
                                        :key="index"
                                        cols="12"
                                        sm="6"
                                        md="4">
                                        <v-card 
                                            class="text-center"
                                            :class="[activeCardClasses(index)]"
                                            @click="clickElement(element, index)">
                                            <v-card-title class="d-flex flex-column gap-2">
                                                
                                                <v-icon
                                                size="34"
                                                >{{ resolveQuestionTypeIcon(element) }}</v-icon>
                                                {{ element.name }}

                                                </v-card-title>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-window-item>
                            <div v-if="selectedElement.element">
                                <div v-if="selectedElement.element.type !== 'group'">
                                    
                                    <v-window-item>
                                        <v-form
                                            :ref="`step1Form`"
                                            v-on:submit.prevent
                                        >
                                                <h3>{{ selectedElement.name }}</h3>
                                                <v-text-field
                                                    v-model="selectedElement.element.name"
                                                    outlined
                                                    dense
                                                    label="Label"
                                                    placeholder="QX"
                                                    class="py-2"
                                                    autofocus
                                                    @keydown.space.prevent
                                                    :rules="[validators.required, validators.questionLabelCheck, validators.decipherLabelValidator, validators.questionLibLabelValidator]"
                                                    v-if="selectedElement.element.name == ''"
                                                ></v-text-field>
                                                <editor
                                                    :modelValue.sync="selectedElement.element.html[localDefaultLang.code]"
                                                    :editor.sync="editor"
                                                    :variable-suggestions="variableSuggestions"
                                                    label="Intro"
                                                    :rules="[validators.required]"
                                                    v-if="selectedElement.element.html"
                                                />
                                                
                                                <editor
                                                    :modelValue.sync="selectedElement.element.title[localDefaultLang.code]"
                                                    :editor.sync="editor"
                                                    :variable-suggestions="variableSuggestions"
                                                    label="Question"
                                                    :rules="[validators.required]"
                                                    v-if="selectedElement.element.title"
                                                />
                                                <editor
                                                    :modelValue.sync="selectedElement.element.description[localDefaultLang.code]"
                                                    :variable-suggestions="variableSuggestions"
                                                    label="Add. Instructions"
                                                    v-if="selectedElement.element.description"
                                                />
                                        </v-form>
                                        
                                    </v-window-item>
                                    <v-window-item>
                                        <v-form
                                            :ref="`step2Form`"
                                            v-on:submit.prevent
                                        >
                                        <v-row dense>

                                            <v-col
                                                
                                                cols="12"
                                                sm="12"
                                                md="12"
                                                lg="12"
                                                v-if="selectedElement.element.headers && selectedElement.element.items"
                                            >

                                                
                                                <datatable
                                                :headers="selectedElement.element.headers"
                                                :items="selectedElement.element.items"
                                                @save-headers="save_headers"
                                                @save-items="save_items"
                                                ></datatable>
                                            </v-col>

                                            <v-col
                                                v-if="selectedElement.element.choices"
                                                cols="12"
                                                sm="12"
                                                md="12"
                                                lg="12">
                                                <span class="pt-4 text-subtitle-1 font-weight-bold">Choices</span>
                                                
                                                <v-row v-for="(item, i) in selectedElement.element.choices"
                                                    :key="i"
                                                    class="ma-0">

                                                    <v-text-field
                                                        v-model="item.text[localDefaultLang.code]"
                                                        outlined
                                                        dense
                                                        hide-details
                                                        :append-icon="!(selectedElement.element.static && selectedElement.element.static.choices)? icons.mdiTrashCanOutline : undefined"
                                                        @click:append="removeItem(i, 'choices')"
                                                        class="pb-2 mr-2"
                                                    ></v-text-field>
                                                    <v-tooltip top v-if="selectedElement.element.choicesOrder">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <span 
                                                                v-bind="attrs"
                                                                v-on="on">
                                                            <v-checkbox
                                                                v-model="item.noShuffle"
                                                                hide-details
                                                                class="shrink mr-2 mt-0"
                                                            ></v-checkbox>
                                                            </span>
                                                        </template>
                                                        <span>Do Not Shuffle</span>
                                                    </v-tooltip>
                                                    <template v-if="selectedElement.element.type == 'checkbox'">
                                                        <v-tooltip top >
                                                            <template v-slot:activator="{ on, attrs }">
                                                                
                                                                <span 
                                                                    v-bind="attrs"
                                                                    v-on="on">
                                                                <v-checkbox
                                                                    v-model="item.exclusive"
                                                                    hide-details
                                                                    class="shrink mr-2 mt-0"
                                                                ></v-checkbox>
                                                                </span>
                                                            </template>
                                                            <span>Exclusive</span>
                                                        </v-tooltip>
                                                    </template>
                                                    
                                                    <v-tooltip top >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <span 
                                                                v-bind="attrs"
                                                                v-on="on">
                                                            <v-checkbox
                                                                v-model="item.showOtherItem"
                                                                hide-details
                                                                class="shrink mr-2 mt-0"
                                                            ></v-checkbox>
                                                            </span>
                                                        </template>
                                                        <span>Show Other (Specify)</span>
                                                    </v-tooltip>
                                                </v-row>
                                                
                                                <v-text-field
                                                    label="Add item"
                                                    hide-details
                                                    outlined
                                                    dense
                                                    v-model="newChoiceItem"
                                                    :append-icon="icons.mdiPlus"
                                                    @keydown.enter.prevent="addItem(newChoiceItem, 'choices')"
                                                    @click:append="addItem(newChoiceItem, 'choices')"
                                                    v-if="!(selectedElement.element.static && selectedElement.element.static.choices)"
                                                >
                                                </v-text-field>

                                                <div v-if="['radiogroup', 'dropdown', 'checkbox'].includes(selectedElement.type)">
                                                    <v-autocomplete
                                                        v-model="selectedElement.element.choicesFromQuestion"
                                                        :items="questionsWithChoices"
                                                        label="Copy choices from the following question"
                                                        hide-detail
                                                        dense
                                                        outlined
                                                        clearable
                                                        class="pt-6"
                                                        placeholder="Select question"
                                                    >

                                                    </v-autocomplete>

                                                    <v-radio-group
                                                        v-model="selectedElement.element.choicesFromQuestionMode"
                                                        label="Which choices to copy"
                                                        row
                                                        class="mt-0"
                                                        v-if="selectedElement.element.choicesFromQuestion"
                                                        :rules="[validators.requiredArray]"
                                                    >
                                                            <v-radio
                                                            label="All"
                                                            value="all"
                                                            >
                                                            </v-radio>
                                                            <v-radio
                                                            label="Selected"
                                                            value="selected"
                                                            ></v-radio>
                                                            <v-radio
                                                            label="Unselected"
                                                            value="unselected"
                                                            ></v-radio>
                                                    </v-radio-group>

                                                </div>

                                            </v-col>
                                            <v-col
                                                v-if="selectedElement.element.columns"
                                                cols="12"
                                                sm="12"
                                                md="12"
                                                lg="12">
                                                <span class="pt-4 text-subtitle-1 font-weight-bold">Columns</span>
                                                
                                                <v-row v-for="(item, i) in selectedElement.element.columns" 
                                                    :key="i"
                                                    class="ma-0">
                                                <v-text-field
                                                    v-model="item.text[localDefaultLang.code]"
                                                    outlined
                                                    dense
                                                    hide-details
                                                    :append-icon="!(selectedElement.element.static && selectedElement.element.static.columns) ? icons.mdiTrashCanOutline : undefined"
                                                    @click:append="removeItem(i, 'columns')"
                                                    class="pb-2"
                                                ></v-text-field>
                                                <!-- <v-tooltip top>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            
                                                            <span 
                                                                v-bind="attrs"
                                                                v-on="on">
                                                            <v-checkbox
                                                                v-model="item.exclusive"
                                                                hide-details
                                                                class="shrink mr-2 mt-0"
                                                            ></v-checkbox>
                                                            </span>
                                                        </template>
                                                        <span>Exclusive</span>
                                                    </v-tooltip> -->
                                                    <v-tooltip top v-if="selectedElement.element.choicesOrder">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <span 
                                                                v-bind="attrs"
                                                                v-on="on">
                                                            <v-checkbox
                                                                v-model="item.noShuffle"
                                                                hide-details
                                                                class="shrink mr-2 mt-0"
                                                            ></v-checkbox>
                                                            </span>
                                                        </template>
                                                        <span>Do Not Shuffle</span>
                                                    </v-tooltip>
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <span 
                                                                v-bind="attrs"
                                                                v-on="on">
                                                            <v-checkbox
                                                                v-model="item.showOtherItem"
                                                                hide-details
                                                                class="shrink mr-2 mt-0"
                                                            ></v-checkbox>
                                                            </span>
                                                        </template>
                                                        <span>Show Other (Specify)</span>
                                                    </v-tooltip>
                                                </v-row>
                                                <v-text-field
                                                    label="Add item"
                                                    hide-details
                                                    outlined
                                                    dense
                                                    v-model="newColumnItem"
                                                    :append-icon="icons.mdiPlus"
                                                    @keydown.enter.prevent="addItem(newColumnItem, 'columns')"
                                                    @click:append="addItem(newColumnItem, 'columns')"
                                                    class="pb-6"
                                                    v-if="!(selectedElement.element.static && selectedElement.element.static.columns)"
                                                >
                                                </v-text-field>
                                                

                                            </v-col>
                                            <v-col
                                                v-if="selectedElement.element.themes"
                                                cols="12"
                                                sm="12"
                                                md="12"
                                                lg="12">
                                                <span class="pt-4 text-subtitle-1 font-weight-bold">Themes</span>
                                                
                                                <div dense
                                                    v-for="(theme,themeIndex) in selectedElement.element.themes"
                                                    :key="themeIndex"
                                                >
                                                        <v-text-field
                                                            v-model="theme.title[localDefaultLang.code]"
                                                            outlined
                                                            dense
                                                            label="Label"
                                                            :append-icon="icons.mdiTrashCanOutline"
                                                            @click:append="removeItem(themeIndex, 'themes')"
                                                            class="py-2"
                                                        ></v-text-field>
                                                    <v-row 
                                                        v-for="(item, i) in theme.rows" 
                                                        :key="i"
                                                        class="ma-0">
                                                        <v-text-field
                                                            v-model="item.text[localDefaultLang.code]"
                                                            outlined
                                                            dense
                                                            hide-details
                                                            :append-icon="!(theme.static && theme.static.rows) ? icons.mdiTrashCanOutline : undefined"
                                                            @click:append="removeItem(i, 'rows', {name: 'themes', index: themeIndex})"
                                                            class="pb-2"
                                                        ></v-text-field>
                                                        <v-tooltip top v-if="selectedElement.element.choicesOrder">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <span 
                                                                    v-bind="attrs"
                                                                    v-on="on">
                                                                <v-checkbox
                                                                    v-model="item.noShuffle"
                                                                    hide-details
                                                                    class="shrink mr-2 mt-0"
                                                                ></v-checkbox>
                                                                </span>
                                                            </template>
                                                            <span>Do Not Shuffle</span>
                                                        </v-tooltip>
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <span 
                                                                    v-bind="attrs"
                                                                    v-on="on">
                                                                <v-checkbox
                                                                    v-model="item.showOtherItem"
                                                                    hide-details
                                                                    class="shrink mr-2 mt-0"
                                                                ></v-checkbox>
                                                                </span>
                                                            </template>
                                                            <span>Show Other (Specify)</span>
                                                        </v-tooltip>
                                                    </v-row>
                                                    <v-text-field
                                                        label="Add item"
                                                        hide-details
                                                        outlined
                                                        dense
                                                        v-model="newThemeRowItem[themeIndex]"
                                                        :append-icon="icons.mdiPlus"
                                                        @keydown.enter.prevent="addItem(newThemeRowItem[themeIndex], 'rows', {name: 'themes', index: themeIndex})"
                                                        @click:append="addItem(newThemeRowItem[themeIndex], 'rows', {name: 'themes', index: themeIndex})"
                                                        class="pb-2"
                                                        v-if="!(theme.static && theme.static.rows)"
                                                    >
                                                    </v-text-field>
                                                
                                                <hr class="my-2"/>
                                                </div>
                                            </v-col>
                                            <v-col
                                                v-if="selectedElement.element.rows"
                                                cols="12"
                                                sm="12"
                                                md="12"
                                                lg="12">
                                                <span class="pt-4 text-subtitle-1 font-weight-bold">Rows</span>
                                                
                                                <v-row 
                                                    v-for="(item, i) in selectedElement.element.rows" 
                                                    :key="i"
                                                    class="ma-0">
                                                    <v-text-field
                                                        v-model="item.text[localDefaultLang.code]"
                                                        outlined
                                                        dense
                                                        hide-details
                                                        :append-icon="!(selectedElement.element.static && selectedElement.element.static.rows) ? icons.mdiTrashCanOutline : undefined"
                                                        @click:append="removeItem(i, 'rows')"
                                                        class="pb-2"
                                                    ></v-text-field>
                                                    <!-- <v-tooltip top >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            
                                                            <span 
                                                                v-bind="attrs"
                                                                v-on="on">
                                                            <v-checkbox
                                                                v-model="item.exclusive"
                                                                hide-details
                                                                class="shrink mr-2 mt-0"
                                                            ></v-checkbox>
                                                            </span>
                                                        </template>
                                                        <span>Exclusive</span>
                                                    </v-tooltip> -->
                                                    <v-tooltip top v-if="selectedElement.element.choicesOrder">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <span 
                                                                v-bind="attrs"
                                                                v-on="on">
                                                            <v-checkbox
                                                                v-model="item.noShuffle"
                                                                hide-details
                                                                class="shrink mr-2 mt-0"
                                                            ></v-checkbox>
                                                            </span>
                                                        </template>
                                                        <span>Do Not Shuffle</span>
                                                    </v-tooltip>
                                                    <!-- <v-tooltip top>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <span 
                                                                v-bind="attrs"
                                                                v-on="on">
                                                            <v-checkbox
                                                                v-model="item.showOtherItem"
                                                                hide-details
                                                                class="shrink mr-2 mt-0"
                                                            ></v-checkbox>
                                                            </span>
                                                        </template>
                                                        <span>Show Other (Specify)</span>
                                                    </v-tooltip> -->
                                                </v-row>
                                                <v-text-field
                                                    label="Add item"
                                                    hide-details
                                                    outlined
                                                    dense
                                                    v-model="newRowItem"
                                                    :append-icon="icons.mdiPlus"
                                                    @keydown.enter.prevent="addItem(newRowItem, 'rows')"
                                                    @click:append="addItem(newRowItem, 'rows')"
                                                    class="pb-6"
                                                        v-if="!(selectedElement.element.static && selectedElement.element.static.rows)"
                                                >
                                                </v-text-field>

                                            </v-col>
                                            <template 
                                                v-if="selectedElement.element.inputType == 'number'">

                                            <v-col
                                                cols="12"
                                                sm="12"
                                                md="12"
                                                lg="12">
                                                
                                                <v-row>
                                                    <v-col
                                                        cols="12"
                                                        md="6"
                                                    >
                                                        <v-text-field
                                                            v-model="selectedElement.element.min"
                                                            label="Min"
                                                            type="number"
                                                            hide-details
                                                            outlined
                                                            dense
                                                        >
                                                        </v-text-field>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        md="6"
                                                    >
                                                        <v-text-field
                                                            v-model="selectedElement.element.max"
                                                            label="Max"
                                                            type="number" 
                                                            hide-details
                                                            outlined
                                                            dense
                                                        >
                                                        </v-text-field>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col
                                                        cols="12"
                                                        md="6"
                                                    >
                                                        <v-switch
                                                        v-model="selectedElement.element.decimal"
                                                        color="primary"
                                                        dense
                                                        :label="`Decimal`"
                                                        class="mt-0 pb-6"
                                                    ></v-switch>
                                                    
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        md="6"
                                                    >
                                                        <v-text-field
                                                        v-model="selectedElement.element.currency"
                                                        label="Currency"
                                                        hide-details
                                                        outlined
                                                        dense
                                                    >
                                                    </v-text-field>
                                                    </v-col>
                                                </v-row>
                                                    
                                            
                                                <!-- <v-text-field
                                                    v-model="selectedElement.element.min"
                                                    label="Min"
                                                    type="number"
                                                    hide-details
                                                    outlined
                                                    dense
                                                    class="pb-6"
                                                >
                                                </v-text-field>
                                                
                                                <v-text-field
                                                    v-model="selectedElement.element.max"
                                                    label="Max"
                                                    type="number" 
                                                    hide-details
                                                    outlined
                                                    dense
                                                    class="pb-6"
                                                >
                                                </v-text-field> -->
                                                
                                                
                                                <!-- <v-switch
                                                    v-model="selectedElement.element.decimal"
                                                    color="primary"
                                                    inset
                                                    :label="`Decimal: ${selectedElement.element.decimal.toString()}`"
                                                ></v-switch> -->
                                            </v-col>
                                            </template>
                                            <v-col
                                                v-if="['eyeflow', 'flash'].includes(selectedElement.element.type)"
                                                cols="12"
                                                sm="12"
                                                md="12"
                                                lg="12">
                                            
                                                <v-text-field
                                                    v-model="selectedElement.element.duration"
                                                    :label="`Duration in ms`"
                                                    type="number"
                                                    hide-details
                                                    outlined
                                                    dense
                                                    class="pb-6"
                                                >
                                                </v-text-field>
                                                <hr>
                                                <v-radio-group
                                                    v-model="selectedElement.element.flag"
                                                    row
                                                    v-if="selectedElement.type == 'eyeflow'"
                                                >
                                                <v-radio
                                                    label="Shelf"
                                                    value="shelf"
                                                ></v-radio>
                                                <v-radio
                                                    label="Pack"
                                                    value="pack"
                                                ></v-radio>
                                                </v-radio-group>
                                                
                                            </v-col>
                                            
                                        </v-row>
                                        <v-row dense >
                                            
                                            <v-col
                                                cols="12"
                                                sm="12"
                                                v-if="['checkbox', 'dropdown', 'radiogroup', 'matrix', 'communication'].includes(selectedElement.type)"
                                                >
                                            <span class="pt-4 text-subtitle-1 font-weight-bold">Other Options.</span>
                                            <v-select
                                                v-model="selectedElement.element.choicesOrder"
                                                :items="choicesOrder"
                                                label="Choices Sort Order"
                                                dense
                                                hide-details
                                                outlined
                                            ></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row dense
                                        >
                                            <v-col cols="12" sm="12"
                                            v-if="selectedElement.element.type === 'checkbox'">
                                                
                                                <v-text-field
                                                    v-model="selectedElement.element.maxSelectedChoices"
                                                    label="Maximum Selected Choices"
                                                    outlined
                                                    dense
                                                    max
                                                    type="number"
                                                    :rules="[validators.between(selectedElement.element.maxSelectedChoices, 0, selectedElement.element.choices.length)]"
                                                    class="pt-4"
                                                >
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                        </v-form>
                                    </v-window-item>
                                    <v-window-item>
                                        
                                        <v-form
                                            :ref="`step3Form`"
                                            v-on:submit.prevent
                                        >
                                            <h6 class="text-h6 my-4">
                                            Programming Instructions
                                            </h6>
                                            <editor
                                                :modelValue.sync="selectedElement.element.notes[localDefaultLang.code]"
                                                :variable-suggestions="variableSuggestions"
                                                label="Instructions"
                                                v-if="selectedElement.element.notes"
                                            ></editor>
                                        </v-form>
                                    </v-window-item>
                                </div>
                                <div v-else>
                                    <div v-for="(question, questIndex) in selectedElement.element.elements"
                                        :key="question.name"
                                    >
                                        
                                        <v-window-item>
                                            <v-form
                                                :ref="`step${(3*questIndex)+1}Form`"
                                                v-on:submit.prevent
                                            >
                                                    <h3>{{ question.name }}</h3>
                                                    <v-text-field
                                                        v-model="question.name"
                                                        outlined
                                                        dense
                                                        label="Label"
                                                        placeholder="QX"
                                                        class="py-2"
                                                        autofocus
                                                        @keydown.space.prevent
                                                        :rules="[validators.required, validators.questionLabelCheck, validators.decipherLabelValidator]"
                                                    ></v-text-field>
                                                    <editor
                                                        :modelValue.sync="question.html[localDefaultLang.code]"
                                                        :variable-suggestions="variableSuggestions"
                                                        :editor.sync="editor"
                                                        label="Intro"
                                                        :rules="[validators.required]"
                                                        v-if="question.html"
                                                    />
                                                    
                                                    <editor
                                                        :modelValue.sync="question.title[localDefaultLang.code]"
                                                        :variable-suggestions="variableSuggestions"
                                                        :editor.sync="editor"
                                                        label="Question"
                                                        :rules="[validators.required]"
                                                        v-if="question.title"
                                                    />
                                                    <editor
                                                        :modelValue.sync="question.description[localDefaultLang.code]"
                                                        :variable-suggestions="variableSuggestions"
                                                        label="Add. Instructions"
                                                        v-if="question.description"
                                                    />
                                            </v-form>
                                            
                                        </v-window-item>
                                        <v-window-item>
                                            <v-form
                                                :ref="`step${(3*questIndex)+2}Form`"
                                                v-on:submit.prevent
                                            >
                                            <v-row dense>
                                                <v-col
                                                    v-if="question.choices"
                                                    cols="12"
                                                    sm="12"
                                                    md="12"
                                                    lg="12">
                                                    <span class="pt-4 text-subtitle-1 font-weight-bold">Choices</span>
                                                    
                                                    <v-row v-for="(item, i) in question.choices"
                                                        :key="i"
                                                        class="ma-0">

                                                        <v-text-field
                                                            v-model="item.text[localDefaultLang.code]"
                                                            outlined
                                                            dense
                                                            hide-details
                                                            :append-icon="!(question.static && question.static.choices) ? icons.mdiTrashCanOutline : undefined"
                                                            @click:append="removeGroupItem(i, 'choices', questIndex)"
                                                            class="pb-2 mr-2"
                                                        ></v-text-field>
                                                        
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <span 
                                                                    v-bind="attrs"
                                                                    v-on="on">
                                                                <v-checkbox
                                                                    v-model="item.showOtherItem"
                                                                    hide-details
                                                                    class="shrink mr-2 mt-0"
                                                                ></v-checkbox>
                                                                </span>
                                                            </template>
                                                            <span>Show Other (Specify)</span>
                                                        </v-tooltip>
                                                        
                                                        <v-tooltip top v-if="!['radiogroup', 'dropdown', 'webshopping'].includes(question.type) ">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                
                                                                <span 
                                                                    v-bind="attrs"
                                                                    v-on="on">
                                                                <v-checkbox
                                                                    v-model="item.exclusive"
                                                                    hide-details
                                                                    class="shrink mr-2 mt-0"
                                                                ></v-checkbox>
                                                                </span>
                                                            </template>
                                                            <span>Exclusive</span>
                                                        </v-tooltip>
                                                        <v-tooltip top v-if="question.choicesOrder">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <span 
                                                                    v-bind="attrs"
                                                                    v-on="on">
                                                                <v-checkbox
                                                                    v-model="item.noShuffle"
                                                                    hide-details
                                                                    class="shrink mr-2 mt-0"
                                                                ></v-checkbox>
                                                                </span>
                                                            </template>
                                                            <span>Do Not Shuffle</span>
                                                        </v-tooltip>
                                                    </v-row>
                                                    
                                                    <v-text-field
                                                        label="Add item"
                                                        hide-details
                                                        outlined
                                                        dense
                                                        v-model="newChoiceItem"
                                                        :append-icon="icons.mdiPlus"
                                                        @keydown.enter.prevent="addGroupItem(newChoiceItem, 'choices', questIndex)"
                                                        @click:append="addGroupItem(newChoiceItem, 'choices', questIndex)"
                                                    v-if="!(question.static && question.static.choices)"
                                                    >
                                                    </v-text-field>
                                                    <div v-if="['radiogroup', 'dropdown', 'checkbox'].includes(question.type)">
                                                        <v-autocomplete
                                                            v-model="question.choicesFromQuestion"
                                                            :items="questionsWithChoices"
                                                            label="Copy choices from the following question"
                                                            hide-detail
                                                            dense
                                                            outlined
                                                            clearable
                                                            class="pt-6"
                                                            placeholder="Select question"
                                                        >

                                                        </v-autocomplete>

                                                        <v-radio-group
                                                            v-model="question.choicesFromQuestionMode"
                                                            label="Which choices to copy"
                                                            row
                                                            class="mt-0"
                                                            v-if="question.choicesFromQuestion"
                                                            :rules="[validators.requiredArray]"
                                                        >
                                                                <v-radio
                                                                label="All"
                                                                value="all"
                                                                >
                                                                </v-radio>
                                                                <v-radio
                                                                label="Selected"
                                                                value="selected"
                                                                ></v-radio>
                                                                <v-radio
                                                                label="Unselected"
                                                                value="unselected"
                                                                ></v-radio>
                                                        </v-radio-group>

                                                    </div>

                                                </v-col>
                                                <v-col
                                                    v-if="question.columns"
                                                    cols="12"
                                                    sm="12"
                                                    md="12"
                                                    lg="12">
                                                    <span class="pt-4 text-subtitle-1 font-weight-bold">Columns</span>
                                                    
                                                    <v-row v-for="(item, i) in question.columns" 
                                                        :key="i"
                                                        class="ma-0">
                                                    <v-text-field
                                                        v-model="item.text[localDefaultLang.code]"
                                                        outlined
                                                        dense
                                                        hide-details
                                                        :append-icon="!(question.static && question.static.columns) ? icons.mdiTrashCanOutline : undefined"
                                                        @click:append="removeGroupItem(i, 'columns', questIndex)"
                                                        class="pb-2"
                                                    ></v-text-field>
                                                    <v-tooltip top>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                
                                                                <span 
                                                                    v-bind="attrs"
                                                                    v-on="on">
                                                                <v-checkbox
                                                                    v-model="item.exclusive"
                                                                    hide-details
                                                                    class="shrink mr-2 mt-0"
                                                                ></v-checkbox>
                                                                </span>
                                                            </template>
                                                            <span>Exclusive</span>
                                                        </v-tooltip>
                                                        <v-tooltip top v-if="question.choicesOrder">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <span 
                                                                    v-bind="attrs"
                                                                    v-on="on">
                                                                <v-checkbox
                                                                    v-model="item.noShuffle"
                                                                    hide-details
                                                                    class="shrink mr-2 mt-0"
                                                                ></v-checkbox>
                                                                </span>
                                                            </template>
                                                            <span>Do Not Shuffle</span>
                                                        </v-tooltip>
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <span 
                                                                    v-bind="attrs"
                                                                    v-on="on">
                                                                <v-checkbox
                                                                    v-model="item.showOtherItem"
                                                                    hide-details
                                                                    class="shrink mr-2 mt-0"
                                                                ></v-checkbox>
                                                                </span>
                                                            </template>
                                                            <span>Show Other (Specify)</span>
                                                        </v-tooltip>
                                                    </v-row>
                                                    <v-text-field
                                                        label="Add item"
                                                        hide-details
                                                        outlined
                                                        dense
                                                        v-model="newColumnItem"
                                                        :append-icon="icons.mdiPlus"
                                                        @keydown.enter.prevent="addGroupItem(newColumnItem, 'columns', questIndex)"
                                                        @click:append="addGroupItem(newColumnItem, 'columns', questIndex)"
                                                        class="pb-6"
                                                        v-if="!(question.static && question.static.columns)"
                                                    >
                                                    </v-text-field>
                                                    

                                                </v-col>
                                                <v-col
                                                    v-if="question.themes"
                                                    cols="12"
                                                    sm="12"
                                                    md="12"
                                                    lg="12">
                                                    <span class="pt-4 text-subtitle-1 font-weight-bold">Themes</span>
                                                    
                                                    <div dense
                                                        v-for="(theme,themeIndex) in question.themes"
                                                        :key="themeIndex"
                                                    >
                                                            <v-text-field
                                                                v-model="theme.title[localDefaultLang.code]"
                                                                outlined
                                                                dense
                                                                label="Label"
                                                                :append-icon="!(theme.static && theme.static.rows) ? icons.mdiTrashCanOutline : undefined"
                                                                @click:append="removeGroupItem(themeIndex, 'themes')"
                                                                class="py-2"
                                                            ></v-text-field>
                                                        <v-row 
                                                            v-for="(item, i) in theme.rows" 
                                                            :key="i"
                                                            class="ma-0">
                                                            <v-text-field
                                                                v-model="item.text[localDefaultLang.code]"
                                                                outlined
                                                                dense
                                                                hide-details
                                                                :append-icon="icons.mdiTrashCanOutline"
                                                                @click:append="removeGroupItem(i, 'rows', questIndex, {name: 'themes', index: themeIndex})"
                                                                class="pb-2"
                                                            ></v-text-field>
                                                            <v-tooltip top v-if="question.choicesOrder">
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <span 
                                                                        v-bind="attrs"
                                                                        v-on="on">
                                                                    <v-checkbox
                                                                        v-model="item.noShuffle"
                                                                        hide-details
                                                                        class="shrink mr-2 mt-0"
                                                                    ></v-checkbox>
                                                                    </span>
                                                                </template>
                                                                <span>Do Not Shuffle</span>
                                                            </v-tooltip>
                                                            <v-tooltip top>
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <span 
                                                                        v-bind="attrs"
                                                                        v-on="on">
                                                                    <v-checkbox
                                                                        v-model="item.showOtherItem"
                                                                        hide-details
                                                                        class="shrink mr-2 mt-0"
                                                                    ></v-checkbox>
                                                                    </span>
                                                                </template>
                                                                <span>Show Other (Specify)</span>
                                                            </v-tooltip>
                                                        </v-row>
                                                        <v-text-field
                                                            label="Add item"
                                                            hide-details
                                                            outlined
                                                            dense
                                                            v-model="newThemeRowItem[themeIndex]"
                                                            :append-icon="icons.mdiPlus"
                                                            @keydown.enter.prevent="addGroupItem(newThemeRowItem[themeIndex], 'rows', questIndex, {name: 'themes', index: themeIndex})"
                                                            @click:append="addGroupItem(newThemeRowItem[themeIndex], 'rows', questIndex, {name: 'themes', index: themeIndex})"
                                                            class="pb-2"
                                                            v-if="!(theme.static && theme.static.rows)"
                                                        >
                                                        </v-text-field>
                                                    
                                                    <hr class="my-2"/>
                                                    </div>
                                                </v-col>
                                                <v-col
                                                    v-if="question.rows"
                                                    cols="12"
                                                    sm="12"
                                                    md="12"
                                                    lg="12">
                                                    <span class="pt-4 text-subtitle-1 font-weight-bold">Rows</span>
                                                    
                                                    <v-row 
                                                        v-for="(item, i) in question.rows" 
                                                        :key="i"
                                                        class="ma-0">
                                                        <v-text-field
                                                            v-model="item.text[localDefaultLang.code]"
                                                            outlined
                                                            dense
                                                            hide-details
                                                            :append-icon="!(question.static && question.static.rows) ? icons.mdiTrashCanOutline : undefined"
                                                            @click:append="removeGroupItem(i, 'rows', questIndex)"
                                                            class="pb-2"
                                                        ></v-text-field>
                                                        <!-- <v-tooltip top >
                                                            <template v-slot:activator="{ on, attrs }">
                                                                
                                                                <span 
                                                                    v-bind="attrs"
                                                                    v-on="on">
                                                                <v-checkbox
                                                                    v-model="item.exclusive"
                                                                    hide-details
                                                                    class="shrink mr-2 mt-0"
                                                                ></v-checkbox>
                                                                </span>
                                                            </template>
                                                            <span>Exclusive</span>
                                                        </v-tooltip> -->
                                                        <v-tooltip top v-if="question.choicesOrder">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <span 
                                                                    v-bind="attrs"
                                                                    v-on="on">
                                                                <v-checkbox
                                                                    v-model="item.noShuffle"
                                                                    hide-details
                                                                    class="shrink mr-2 mt-0"
                                                                ></v-checkbox>
                                                                </span>
                                                            </template>
                                                            <span>Do Not Shuffle</span>
                                                        </v-tooltip>
                                                        <!-- <v-tooltip top>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <span 
                                                                    v-bind="attrs"
                                                                    v-on="on">
                                                                <v-checkbox
                                                                    v-model="item.showOtherItem"
                                                                    hide-details
                                                                    class="shrink mr-2 mt-0"
                                                                ></v-checkbox>
                                                                </span>
                                                            </template>
                                                            <span>Show Other (Specify)</span>
                                                        </v-tooltip> -->
                                                    </v-row>
                                                    <v-text-field
                                                        label="Add item"
                                                        hide-details
                                                        outlined
                                                        dense
                                                        v-model="newRowItem"
                                                        :append-icon="icons.mdiPlus"
                                                        @keydown.enter.prevent="addGroupItem(newRowItem, 'rows', questIndex)"
                                                        @click:append="addGroupItem(newRowItem, 'rows', questIndex)"
                                                        class="pb-6"
                                                        v-if="!(question.static && question.static.rows)"
                                                    >
                                                    </v-text-field>

                                                </v-col>
                                                <v-col
                                                    v-if="question.inputType == 'number'"
                                                    cols="12"
                                                    sm="12"
                                                    md="12"
                                                    lg="12">
                                                    <v-row>
                                                        <v-col
                                                            cols="12"
                                                            md="6"
                                                        >
                                                            <v-text-field
                                                                v-model="question.min"
                                                                label="Min"
                                                                type="number"
                                                                hide-details
                                                                outlined
                                                                dense
                                                            >
                                                            </v-text-field>
                                                        </v-col>
                                                        <v-col
                                                            cols="12"
                                                            md="6"
                                                        >
                                                            <v-text-field
                                                                v-model="question.max"
                                                                label="Max"
                                                                type="number" 
                                                                hide-details
                                                                outlined
                                                                dense
                                                            >
                                                            </v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col
                                                            cols="12"
                                                            md="6"
                                                        >
                                                            <v-switch
                                                            v-model="question.decimal"
                                                            color="primary"
                                                            dense
                                                            :label="`Decimal`"
                                                            class="mt-0 pb-6"
                                                        ></v-switch>
                                                        
                                                        </v-col>
                                                        <v-col
                                                            cols="12"
                                                            md="6"
                                                        >
                                                            <v-text-field
                                                            v-model="question.currency"
                                                            label="Currency"
                                                            hide-details
                                                            outlined
                                                            dense
                                                            class="pb-6"
                                                        >
                                                        </v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                    

                                                </v-col>
                                                <v-col
                                                    v-if="['eyeflow', 'flash'].includes(question.type)"
                                                    cols="12"
                                                    sm="12"
                                                    md="12"
                                                    lg="12">
                                                
                                                    <v-text-field
                                                        v-model="question.duration"
                                                        :label="`Duration in ms`"
                                                        type="number"
                                                        hide-details
                                                        outlined
                                                        dense
                                                        class="pb-6"
                                                    >
                                                    </v-text-field>
                                                    <hr>
                                                    <v-radio-group
                                                        v-model="question.flag"
                                                        row
                                                        v-if="selectedElement.type == 'eyeflow'"
                                                    >
                                                    <v-radio
                                                        label="Shelf"
                                                        value="shelf"
                                                    ></v-radio>
                                                    <v-radio
                                                        label="Pack"
                                                        value="pack"
                                                    ></v-radio>
                                                    </v-radio-group>
                                                    
                                                </v-col>
                                                
                                            </v-row>
                                            <v-row dense >
                                                
                                                <v-col
                                                    cols="12"
                                                    sm="12"
                                                    v-if="['checkbox', 'radiogroup', 'matrix', 'communication'].includes(selectedElement.type)"
                                                    >
                                                <span class="pt-4 text-subtitle-1 font-weight-bold">Other Options.</span>
                                                <v-select
                                                    v-model="question.choicesOrder"
                                                    :items="choicesOrder"
                                                    label="Choices Sort Order"
                                                    dense
                                                    hide-details
                                                    outlined
                                                ></v-select>
                                                </v-col>
                                            </v-row>
                                            <v-row dense
                                            >
                                                <v-col cols="12" sm="12"
                                                v-if="question.type === 'checkbox'">
                                                    
                                                    <v-text-field
                                                        v-model="question.maxSelectedChoices"
                                                        label="Maximum Selected Choices"
                                                        outlined
                                                        dense
                                                        max
                                                        type="number"
                                                        :rules="[validators.between(question.maxSelectedChoices, 0, question.choices.length)]"
                                                        class="pt-4"
                                                    >
                                                    </v-text-field>
                                                </v-col>
                                            </v-row>
                                            </v-form>
                                        </v-window-item>
                                        <v-window-item>
                                            
                                            <v-form
                                                :ref="`step${(3*questIndex)+3}Form`"
                                                v-on:submit.prevent
                                            >
                                                <h6 class="text-h6 my-4">
                                                Programming Instructions
                                                </h6>
                                                <editor
                                                    :modelValue.sync="question.notes[localDefaultLang.code]"
                                                    :variable-suggestions="variableSuggestions"
                                                    label="Instructions"
                                                    v-if="question.notes"
                                                ></editor>
                                            </v-form>
                                        </v-window-item>

                                    </div>
                                    
                                </div>
                        </div>
                        </v-window>

                        <div class="d-flex justify-space-between mt-8">
                            <v-btn
                                variant="outlined"
                                color="secondary"
                                :disabled="currentStep === 0"
                                @click="clickPrevious"
                            >
                                <v-icon
                                start
                                class="flip-in-rtl"
                                >{{ icons.mdiArrowLeft }}</v-icon>
                                Previous
                            </v-btn>

                            <v-btn
                                v-if="isSubmitReady"
                                color="success"
                                @click="onSubmit"
                            >
                                submit
                            </v-btn>

                            <v-btn
                                v-if="!isSubmitReady && currentStep > 0"
                                @click="checkStepValidation(currentStep+1)"
                            >
                                Next

                                <v-icon
                                end
                                class="flip-in-rtl"
                                >{{ icons.mdiArrowRight }}</v-icon>
                            </v-btn>
                            </div>
                    </v-col>
                </v-row>
                <v-row v-else>
                    <v-col cols="12" sm="12">
                        <v-form
                            :ref="`questionnaireForm`"
                            v-on:submit.prevent
                        >
                        
                        <v-data-table
                            v-model="selected"
                            :headers="questionnaireHeaders"
                            :group-by="groupBy"
                            :items="questionnaireOptions"
                            ref="projectTable"
                            item-key="id"
                            show-select
                            dense
                            hide-default-footer
                            disable-pagination
                        >
                            <template v-slot:[`header.data-table-select`]></template>
                            
                            <template v-slot:[`header.name`]="{header}">
                            <span class="v-label">
                                {{ header.text.toUpperCase() }}
                            </span>
                            </template>
                            <template v-slot:[`header.title`]="{header}">
                            <span class="v-label" :key="header.title">
                            </span>
                            </template>
                            <template v-slot:[`group.header`]="{group, items, toggle, isOpen}">
                            <th colspan="3"
                                    @click="toggle"
                                    :data-open="isOpen"
                            >
                                <td class="text-start">
                                    <label class="v-label  cursor-pointer">{{items[0].job}}</label>
                                    
                                </td>
                                <td class="text-end">
                                <v-btn
                                    :ref="group"
                                    icon
                                    small
                                >
                                    <v-icon v-if="isOpen">{{ icons.mdiMinus }}</v-icon>
                                    <v-icon v-else>{{ icons.mdiPlus }}</v-icon>
                                </v-btn>
                                </td>
                            
                            </th>
                                
                                
                            </template>

                            
                            <template #[`item.title`]="{item}">
                            <div class="d-flex flex-column ms-3" v-if="item.title">
                                <span class="text--primary">{{ item.title.en }}</span>
                            </div>
                            </template>

                        </v-data-table>
                        </v-form>
                    </v-col>
                    <v-card-actions v-if="selected.length > 0">
                        <v-btn
                            text
                            @click="resetElements"
                        >
                            Cancel
                        </v-btn>
                        <v-btn
                            color="primary"
                            @click="onSubmit"
                        >
                            Add Question(s)
                        </v-btn>
                    </v-card-actions>
                </v-row>
            </v-card-text>

        </v-card>

    </v-dialog>
    
</template>

<script>
import { ref, watch, computed, getCurrentInstance } from '@vue/composition-api'
import {
    mdiRadioboxMarked,
    mdiCheckboxMarkedOutline,
    mdiFormDropdown,
    mdiTextBoxOutline,
    mdiFormTextbox,
    mdiGrid,
    mdiClipboardOutline,
    mdiCubeOutline,
    mdiDatabaseOutline,
    mdiNumeric,
    mdiCheck,
    mdiArrowLeft,
    mdiArrowRight,
    mdiTrashCanOutline,
    mdiPlus,
    mdiEyeOutline,
    mdiCartOutline,
    mdiFlashOutline,
    mdiThumbsUpDownOutline,
    mdiThumbUpOutline,
    mdiThumbDownOutline,
    mdiLabelMultipleOutline,
    mdiStoreSearchOutline,
    mdiCursorText,
    mdiSkipPreviousOutline,
    mdiGroup,
    mdiPackageVariantClosed,
    mdiImageMultipleOutline,
    mdiMonitorScreenshot,
    mdiTortoise,
    mdiRabbitVariantOutline,
    mdiHeadDotsHorizontalOutline,
    mdiFilterCogOutline,
    mdiSitemapOutline,
    mdiTable,
    mdiPackageVariantClosedCheck,
    mdiSendOutline,
    mdiMinus
} from '@mdi/js'
import { required, between, requiredArray, decipherLabelValidator } from '@core/utils/validation'
import Editor from '@/views/components/editor/Editor.vue'
import Datatable from '@/views/components/datatable/Datatable.vue'

import useSurvey from '../../useSurvey'

import AppStepper from '@core/components/AppStepper.vue'

export default ({
    components: {
        AppStepper,
        Editor,
        Datatable
    },
    props: {
        isDialogVisible: {
            type: Boolean,
            required: true,
        },
        indexPage: {
            type: Number,
            required: true,
        },
        questionLabelList: {
            type: Set,
            default:() => new Set(),
        },
        defaultLang: {
            type: Object,
            required: true
        },
        questionsWithChoices: {
        type: Array,
        default: () => []
        },
        variableSuggestions: {
            type: Array,
            default: () => []
        },
        cellCount:{
            type: Number,
            required: true
        }
    },
    setup(props, {emit}) {
        const vm = getCurrentInstance().proxy
        const currentStep = ref(0)
        const editor = ref(null)
        const searchQuery = ref('')
        const searchProject = ref(false)
        
        const isCurrentStepValid = ref(true)
        const currentElementIndex = ref(0)
        const selectedElement = ref({})
        const createElement = ref([
            {
                icon: mdiClipboardOutline,
                title: 'Elements',
                subtitle: 'Question Type',
            },
            // {
            //     icon: mdiCubeOutline,
            //     title: 'Questions',
            //     subtitle: 'Question Info',
            // },
            // {
            //     icon: mdiDatabaseOutline,
            //     title: 'Answers',
            //     subtitle: 'Answers Info',
            // },
            // {
            //     icon: mdiCheck,
            //     title: 'Prog. Instructions',
            //     subtitle: 'Additional instructions',
            // },
        ])
        const localDefaultLang = ref({})
        localDefaultLang.value = JSON.parse(JSON.stringify(props.defaultLang))

        
        const questionnaireHeaders = [
            { text: 'Project', value: 'job', align: 'start', sortable: false, filterable:true},
            { text: 'Project / Question', value: 'name', align: 'start', sortable: false, filterable:true},
            { text: 'Text', value: 'title', align: 'start', sortable: false, filterable:true},
        ]
        const selected = ref([])
        const groupBy = ['job']
        const selectedJob = ref([])
        const search = ref('')
        
        const {
            choicesOrder,
            isObjectEmpty,
            
            fetchElements,
            fetchQuestionnaires,
            questionnaireOptions,
            elementLoading,
            elementOptions,
            questionLabels
        } = useSurvey()

        const newChoiceItem = ref("")
        const newColumnItem = ref("")
        const newRowItem = ref("")
        const newThemeRowItem = ref(["", "", ""])

        const resolveQuestionTypeIcon = (element) => {
            const type = element.type
            if (type === 'radiogroup') return mdiRadioboxMarked
            if (type === 'checkbox') return mdiCheckboxMarkedOutline
            if (type === 'dropdown') return mdiFormDropdown
            if (type === 'text') return mdiFormTextbox
            if (type === 'numeric') return mdiNumeric
            if (type === 'comment') return mdiTextBoxOutline
            if (type === 'matrix') return mdiGrid
            if (type === 'eyeflow') return mdiEyeOutline
            if (type === 'shopping') return mdiCartOutline 
            if (type === 'flash') return mdiFlashOutline
            if (type === 'instruction') return mdiCursorText
            if (type === 'like-dislike') return mdiThumbsUpDownOutline
            if (type === 'like') return mdiThumbUpOutline 
            if (type === 'dislike') return mdiThumbDownOutline  
            if (type === 'communication') return mdiLabelMultipleOutline 
            if (type === 'findability') return mdiStoreSearchOutline
            if (type === 'previous') return mdiSkipPreviousOutline
            if (type === 'system2') return mdiTortoise
            if (type === 'system1') return mdiRabbitVariantOutline
            if (type === 'default') return mdiMonitorScreenshot
            if (type === 'behavioral') return mdiHeadDotsHorizontalOutline
            if (type === 'screener') return mdiFilterCogOutline
            if (type === 'classification') return mdiSitemapOutline
            if (type === 'group') return mdiGroup
            if (type === 'resource') return mdiPackageVariantClosed
            if (type === 'stimuli') return mdiImageMultipleOutline
            if (type === 'datatable') return mdiTable
            if (type === 'npd') return mdiPackageVariantClosedCheck
            
            return mdiRadioboxMarked
        }

        const elementCategories = ref([
            
            {
                name: "Custom",
                type: "default"
            },
            {
                name: "Screener",
                type: "screener"
            },
            {
                name: "Beh. Metrics",
                type: "behavioral"
            },
            {
                name: "System 1",
                type: "system1"
            },
            {
                name: "System 2",
                type: "system2"
            },
            {
                name: "NPD",
                type: "npd"
            },
            {
                name: "Classifications",
                type: "classification"
            },
            {
                name: "Stimuli Recap",
                type: "stimuli"
            }
        ])

        const localElements = ref([])

        const labelRule = (value, prefix) => {
            if (prefix.length > 0){
                const searchPattern = new RegExp('^'+prefix)
                return searchPattern.test(value) || `Question label needs to start with : ${prefix}`
            }
            return true
        }
        const questionLibLabelValidator = label => {
            
            return questionLabels.filter(element => element == label).length == 0 || "Question Label already exist in Question Library."
        }

        const questionLabelCheck = (label) => {
            
            return [...props.questionLabelList].filter(element => element == label).length == 0 || "Question Label already exist"
        }

        const resetElements = () => {
            localElements.value = JSON.parse(JSON.stringify(elementCategories.value))
            questionnaireOptions.value = []
            selected.value = []
            searchQuery.value = null
            searchProject.value = false
            selectedElement.value = {}
            currentElementIndex.value = undefined
            createElement.value = [
                {
                    icon: mdiClipboardOutline,
                    title: 'Elements',
                    subtitle: 'Question Type',
                },
                
            ]
        }

        const clickPrevious = () => {
            currentStep.value--
            if (currentStep.value == 0) resetElements()
        }

        const clearSearch = () => {

            resetElements()
        }

        const searchElements = async (event) => {
            if (event) {
                event.preventDefault()
            }
            if (searchQuery.value === null) searchQuery.value = '';
            if (searchProject.value)  await fetchQuestionnaires({search:searchQuery.value})
            else await fetchElements({search: searchQuery.value})
            localElements.value = elementOptions.value
        }
        

        const clickElement = async (element, index) => {
            if(element.id === undefined){

                await fetchElements({search: element.type})
                localElements.value = elementOptions.value
            } else {

                if(element.type === 'library'){
                    localElements.value = library
                } else if (element.type === 'previous') {
                    resetElements()
                } else {
                    if(element.element.type == 'group'){
                        element.element.elements.forEach(question => {
                            createElement.value.push(
                            {
                                icon: mdiCubeOutline,
                                title: `${question.name} Question`,
                                subtitle: 'Question Info',
                            },
                            {
                                icon: mdiDatabaseOutline,
                                title: `${question.name} Answer`,
                                subtitle: 'Answers Info',
                            },
                            {
                                icon: mdiCheck,
                                title: `${question.name} Inst.`,
                                subtitle: 'Additional instructions',
                            })
                        })
                        
                    } else {
                        createElement.value.push(
                            {
                                icon: mdiCubeOutline,
                                title: `${element.name} Question`,
                                subtitle: 'Question Info',
                            },
                            {
                                icon: mdiDatabaseOutline,
                                title: `${element.name} Answer`,
                                subtitle: 'Answers Info',
                            },
                            {
                                icon: mdiCheck,
                                title: `${element.name} Inst.`,
                                subtitle: 'Additional instructions',
                            })
                    }
                    
                    if(element.type === 'stimuli'){
                        element.element.headers = [{"text": "Question", "align": "start", "value": "name", "active": true, "sortable": false}]
                        for(let i=0; i<props.cellCount; i++){
                            element.element.headers.push({"text": `Cell ${i+1}`, "value": `col_${i+1}`, "active": true})
                        }
                    }
                    selectedElement.value = element
                    currentElementIndex.value = index
                    currentStep.value++
                    if(element.category != 'default') {
                        onSubmit()
                    }
                }
            }

            
        }

        // const removeTheme = (index, localList) => {
        //     const list = selectedElement.value.element.themes
        //     const newList = [
        //         ...list.slice(0, index),
        //         ...list.slice(index + 1, list.length)
        //     ]
        //     selectedElement.value.element[level.name][level.index][localList] = newList

        // }

        
        const removeGroupItem = (index, localList, group, level=null) => {
            if (level !== null) {
                const list = selectedElement.value.element.elements[group][level.name][level.index][localList]
                const newList = [
                    ...list.slice(0, index),
                    ...list.slice(index + 1, list.length)
                ]
                selectedElement.value.element.elements[group][level.name][level.index][localList] = newList
            
            } else {
                const list = selectedElement.value.element.elements[group][localList]
                const newList = [
                    ...list.slice(0, index),
                    ...list.slice(index + 1, list.length)
                ]
                selectedElement.value.element.elements[group][localList] = newList
            }    
            
        }

        const addGroupItem = (newVal, localList, group, level=null) => {

            
            if (newVal) {
                if (level !== null) {
                    const list = selectedElement.value.element.elements[group][level.name][level.index][localList]
                    const newItem = {
                        value: `${localList === 'columns' ? 'c' : 'r'}${list.length+1}`,
                        text: {
                            [localDefaultLang.value.code]: newVal
                        }
                    }
                    const newList = [...list, newItem]
                    selectedElement.value.element.elements[group][level.name][level.index][localList] = newList
                
                } else {

                    const list = selectedElement.value.element.elements[group][localList]
                    const newItem = {
                        value: `${localList === 'columns' ? 'c' : 'r'}${list.length+1}`,
                        text: {
                            [localDefaultLang.value.code]: newVal
                        }
                    }
                    const newList = [...list, newItem]
                    selectedElement.value.element.elements[group][localList] = newList
                }
                    newChoiceItem.value = ""
                    newColumnItem.value = ""
                    newRowItem.value = ""
                    newThemeRowItem.value = ["", "", ""]
            }
        }

        const removeItem = (index, localList, level=null) => {
            if (level !== null) {
                const list = selectedElement.value.element[level.name][level.index][localList]
                const newList = [
                    ...list.slice(0, index),
                    ...list.slice(index + 1, list.length)
                ]
                selectedElement.value.element[level.name][level.index][localList] = newList
            
            } else {
                const list = selectedElement.value.element[localList]
                const newList = [
                    ...list.slice(0, index),
                    ...list.slice(index + 1, list.length)
                ]
                selectedElement.value.element[localList] = newList
            }    
            
        }

        const addItem = (newVal, localList, level=null) => {

        
            if (newVal) {
                if (level !== null) {
                    const list = selectedElement.value.element[level.name][level.index][localList]
                    const newItem = {
                        value: `${localList === 'columns' ? 'c' : 'r'}${list.length+1}`,
                        text: {
                            [localDefaultLang.value.code]: newVal
                        }
                    }
                    const newList = [...list, newItem]
                    selectedElement.value.element[level.name][level.index][localList] = newList
                
                } else {

                    const list = selectedElement.value.element[localList]
                    const newItem = {
                        value: `${localList === 'columns' ? 'c' : 'r'}${list.length+1}`,
                        text: {
                            [localDefaultLang.value.code]: newVal
                        }
                    }
                    const newList = [...list, newItem]
                    selectedElement.value.element[localList] = newList
                }
                    newChoiceItem.value = ""
                    newColumnItem.value = ""
                    newRowItem.value = ""
                    newThemeRowItem.value = ["", "", ""]
            }
        }

        

        const checkStepValidation = async (step) => {
            let formRef = `step${currentStep.value}Form`

            // Get Form templateRef of Step from argument
            const templateRef = (() => {
                //Check Ref is array format or not. When dynamically adding ref in v-for loop it creates an array instead of instance
                if (currentStep.value >= 0) return Array.isArray(vm.$refs[formRef]) ? vm.$refs[formRef][0] : vm.$refs[formRef]
                
                return null
            })()
            
            // If no validate step templateRef is found just return
            if (!templateRef) return

            // If step is valid proceed to the next step
            const isEditorValid = isObjectEmpty(editor.value) || editor.value === null ? true : editor.value.isValid()

            const isStepValid = templateRef.validate()
            if (isStepValid && isEditorValid) {
                currentStep.value = step
                isCurrentStepValid.value = true
            } else {
                
                isCurrentStepValid.value = false
            }
        }

        const isSubmitReady = computed(() => {
            if (currentStep.value === 0) return false
            return createElement.value.length - 1 === currentStep.value
            
        })

        const save_headers = (headers) => {
            selectedElement.value.element.headers = headers
        }
        const save_items = (items) => {
            selectedElement.value.element.items = items
        }


        const onSubmit = () => {
            if (searchProject.value) {
                selected.value.map(selectedEl => {
                    delete selectedEl.sectionDesc
                    delete selectedEl.sectionTitle
                    delete selectedEl.value_name

                    const element = {
                        element: selectedEl
                    }
                
                    let dupName = false
                        let indexLabel = 0
                        var name = ""
                        
                        if(element.element.name.length > 0){
                            name = element.element.name
                        } else {
                            name = element.element.valueName
                            element.element.name = name
                        }
                        while(!dupName){
                            if([...props.questionLabelList].filter(label => label == element.element.name).length == 0){
                                dupName = true
                            } else {
                                indexLabel++
                                element.element.name = `QX_${name}_${indexLabel}`
                            }
                        }



                        if (element.element.type === 'group'){
                            let dupName = false
                            let indexLabel = 0
                            
                            while(!dupName){
                                if([...props.questionLabelList].filter(label => label == element.element.name).length == 0){
                                    dupName = true
                                } else {
                                    indexLabel++
                                    element.element.name = `QX_${element.element.name}_${indexLabel}`
                                }
                            }

                            element.element.elements.forEach(question => {
                                dupName = false
                                indexLabel = 0
                                var name = ""
                                if(question.name.length > 0){
                                    name = question.name
                                } else {
                                    name = question.valueName
                                }
                                while(!dupName){
                                    if([...props.questionLabelList].filter(label => label == question.name).length == 0){
                                        dupName = true
                                    } else {
                                        indexLabel++
                                        question.name = `QX_${name}_${indexLabel}`
                                    }
                                }

                            })
                        }
                        emit('add-element', props.indexPage, element)

                })
                    
            } else {
            checkStepValidation(currentStep.value)
            if (isCurrentStepValid.value){
                delete selectedElement.value.sectionDesc
                delete selectedElement.value.sectionTitle
                const element = JSON.parse(JSON.stringify(selectedElement.value))
                
                let dupName = false
                    let indexLabel = 0
                    var name = ""
                    
                    if(element.element.name.length > 0){
                        name = element.element.name
                    } else {
                        name = element.element.valueName
                        element.element.name = name
                    }
                    while(!dupName){
                        if([...props.questionLabelList].filter(label => label == element.element.name).length == 0){
                            dupName = true
                        } else {
                            indexLabel++
                            element.element.name = `${name}_${indexLabel}`
                        }
                    }



                    if (element.element.type === 'group'){
                        let dupName = false
                        let indexLabel = 0
                        
                        while(!dupName){
                            if([...props.questionLabelList].filter(label => label == element.element.name).length == 0){
                                dupName = true
                            } else {
                                indexLabel++
                                element.element.name = `${element.element.name}_${indexLabel}`
                            }
                        }

                        element.element.elements.forEach(question => {
                            dupName = false
                            indexLabel = 0
                            var name = ""
                            if(question.name.length > 0){
                                name = question.name
                            } else {
                                name = question.valueName
                            }
                            while(!dupName){
                                if([...props.questionLabelList].filter(label => label == question.name).length == 0){
                                    dupName = true
                                } else {
                                    indexLabel++
                                    question.name = `${name}_${indexLabel}`
                                }
                            }

                        })
                    }
                    emit('add-element', props.indexPage, element)
                }
            }
        }

        const activeCardClasses = computed(() => index => index === currentElementIndex.value ? 'active' : '')

        watch(
        () => questionnaireOptions.value,
        (newVal, oldVal) => {
            if (newVal.length > 0) {
              setTimeout(() => {
                
                let table = vm.$refs.projectTable
                let keys = Object.keys(table.$vnode.componentInstance.openCache)
                keys.forEach(x => {
                  table.$vnode.componentInstance.openCache[x] = false;
                })
              }, 100)
            }
        }
      )
        watch(
            props, 
            () => {
            if (!props.isDialogVisible)
                currentStep.value = 0
                resetElements()
            }
        )

        watch(
        () => props.defaultLang,
        () => {
            localDefaultLang.value = JSON.parse(JSON.stringify(props.defaultLang))
        }
        )

        
        return {
            createElement,
            localElements,
            resetElements,
            elementLoading,
            currentStep,
            checkStepValidation,
            isCurrentStepValid,
            resolveQuestionTypeIcon,
            localDefaultLang,
            searchQuery,
            searchProject,

            newChoiceItem,
            newColumnItem,
            newRowItem,
            newThemeRowItem,

            selectedElement,
            clickElement,
            removeItem,
            addItem,

            removeGroupItem,
            addGroupItem,
            clickPrevious,
            currentElementIndex,
            activeCardClasses,

            choicesOrder,
            save_headers,
            save_items,

            editor,

            onSubmit,
            isSubmitReady,
            searchElements,
            clearSearch,

            questionnaireHeaders,
            questionnaireOptions,
            selected,
            groupBy,

            icons: {
                mdiTrashCanOutline,
                mdiPlus,
                mdiArrowLeft,
                mdiArrowRight,
                mdiSendOutline,
                mdiMinus
            },

            validators: {
                required,
                questionLabelCheck,
                between,
                labelRule,
                requiredArray,
                decipherLabelValidator,
                questionLibLabelValidator
            },
            
        }
    },
})
</script>


<style lang="scss">
.stepper-content .card-list {
  --v-card-list-gap: 24px;
}

.flexcol .v-btn__content {
  flex-direction: column;
}
.v-card .active {
  background: var(--v-primary-base);
  .v-card__title {
    color:white !important;
    .v-icon {
        color: white;
    }
  }
}

// .cursor-pointer {
//     cursor: pointer;
// }

.tiptap-vuetify-editor__content {
  padding: 12px;
  .ProseMirror-focused {
      outline: none;
  }

}


// .v-card--link:focus::before {
//   opacity: 0;
// }
</style>
