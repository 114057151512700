import store from '@/store'
import axios from '@axios'
import * as flo from 'axios'
import { transformRequestOptions } from '@core/utils'

export default {
    namespaced: true,
    state: {
        
    },
    getters: {
        
    },
    mutations: {
        
    },
    actions: {
        fetchElements(ctx, queryParams ) {
            store.commit('app/LOADING', true)
            return new Promise((resolve, reject) => {
                axios
                .get(`/surveys/elements/`, {
                    params: queryParams,
                    paramsSerializer: params => transformRequestOptions(params)
                })
                .then(response => {
                    store.commit('app/LOADING', false)
                    resolve(response)
                })
                .catch(error => reject(error))
            })
        },
        fetchProject(ctx, queryParams ) {
            store.commit('app/LOADING', true)
            return new Promise((resolve, reject) => {
                axios
                .get(`/surveys/paper/${queryParams.id}/country/${queryParams.country}/`, {
                    params: queryParams,
                    paramsSerializer: params => transformRequestOptions(params)
                })
                .then(response => {
                    store.commit('app/LOADING', false)
                    resolve(response)
                })
                .catch(error => reject(error))
            })
        },
        addProjectQuestionnaire(ctx, payload ) {
            store.commit('app/LOADING', true)
            return new Promise((resolve, reject) => {
                axios
                .post(`/surveys/questionnaire/`, payload)
                .then(response => {
                    store.commit('app/LOADING', false)
                    resolve(response)
                })
                .catch(error => reject(error))
            })
        },
        downloadQuestionnaire(ctx, data ) {
            store.commit('app/LOADING', true)
            
            return new Promise((resolve, reject) => {
                axios
                .patch(`/surveys/questionnaire/${data.questionnaire.id}/docx/`, data.questionnaire, { responseType:'blob'})
                .then(response => {
                    store.commit('app/LOADING', false)
                    resolve(response)
                })
                .catch(error => reject(error))
            })
        },
        downloadQuestionnaireVersion(ctx, id ) {
            store.commit('app/LOADING', true)
            return new Promise((resolve, reject) => {
                axios
                .patch(`/surveys/versions/${id}/json/`, {}, { responseType:'blob'})
                .then(response => {
                    store.commit('app/LOADING', false)
                    resolve(response)
                })
                .catch(error => reject(error))
            })
        },
        
        updateVersion(ctx, data ) {
            store.commit('app/LOADING', true)
            return new Promise((resolve, reject) => {
                axios
                .patch(`/surveys/versions/${data.id}/`, data)
                .then(response => {
                    store.commit('app/LOADING', false)
                    resolve(response)
                })
                .catch(error => {    
                    store.commit('app/LOADING', false)
                    reject(error)}
                )
            })
        },
        downloadWordQuestionnaireVersion(ctx, id ) {
            store.commit('app/LOADING', true)
            return new Promise((resolve, reject) => {
                axios
                .patch(`/surveys/versions/${id}/docx/`, {}, { responseType:'blob'})
                .then(response => {
                    store.commit('app/LOADING', false)
                    resolve(response)
                })
                .catch(error => reject(error))
            })
        },
        updateQuestionnaire(ctx, data ) {
            store.commit('app/LOADING', true)
            return new Promise((resolve, reject) => {
                axios
                .patch(`/surveys/questionnaire/${data.id}/`, data)
                .then(response => {
                    store.commit('app/LOADING', false)
                    resolve(response)
                })
                .catch(error => {    
                    store.commit('app/LOADING', false)
                    reject(error)}
                )
            })
        },
        deleteQuestionnaire(ctx, id ) {
            store.commit('app/LOADING', true)
            return new Promise((resolve, reject) => {
                axios
                .delete(`/surveys/questionnaire/${id}/`)
                .then(response => {
                    store.commit('app/LOADING', false)
                    resolve(response)
                })
                .catch(error => {    
                    store.commit('app/LOADING', false)
                    reject(error)}
                )
            })
        },
        fetchQuestionnaires(ctx, queryParams) {
            store.commit('app/LOADING', true)
            return new Promise((resolve, reject) => {
              axios
                .get(`/surveys/questionnaire/`, {
                    params: queryParams,
                    paramsSerializer: params => transformRequestOptions(params)
                  }
                )
                .then(response => {
                  store.commit('app/LOADING', false)
                  resolve(response)
                })
                .catch(error => {
                  store.commit('app/LOADING', false)
                  reject(error)
                })
            })
        },
        fetchLanguages(ctx, queryParams ) {
            store.commit('app/LOADING', true)
            return new Promise((resolve, reject) => {
                axios
                .get(`/surveys/languages/`)
                .then(response => {
                    store.commit('app/LOADING', false)
                    resolve(response)
                })
                .catch(error => reject(error))
            })
        },
        fetchQuestionnaireCategories(ctx, queryParams ) {
            store.commit('app/LOADING', true)
            return new Promise((resolve, reject) => {
                axios
                .get(`/surveys/categories/`)
                .then(response => {
                    store.commit('app/LOADING', false)
                    resolve(response)
                })
                .catch(error => reject(error))
            })
        },
        fetchQuestionnaireResearchMethods(ctx, queryParams ) {
            store.commit('app/LOADING', true)
            return new Promise((resolve, reject) => {
                axios
                .get(`/surveys/research_methods/`)
                .then(response => {
                    store.commit('app/LOADING', false)
                    resolve(response)
                })
                .catch(error => reject(error))
            })
        },
        addQuestionnaireCategory(ctx, payload ) {
            store.commit('app/LOADING', true)
            return new Promise((resolve, reject) => {
                axios
                .post(`/surveys/questionnaire/`, payload)
                .then(response => {
                    store.commit('app/LOADING', false)
                    resolve(response)
                })
                .catch(error => reject(error))
            })
        },
        fetchTemplate(ctx, {id} ) {
            store.commit('app/LOADING', true)
            return new Promise( (resolve, reject) => {
                axios
                .get(`/surveys/templates/${id}/`)
                .then(response => {
                    store.commit('app/LOADING', false)
                    resolve(response)
                })
                .catch(error => reject(error))
            })
        },

        fetchTemplates(ctx, queryParams ) {
            store.commit('app/LOADING', true)
            return new Promise((resolve, reject) => {
                axios
                .get(`/surveys/templates/`, {
                    params: queryParams,
                    paramsSerializer: params => transformRequestOptions(params)
                  })
                .then(response => {
                    store.commit('app/LOADING', false)
                    resolve(response)
                })
                .catch(error => reject(error))
            })
        },
        translateQuestionnaire(ctx, data) {
            store.commit('app/LOADING', true)
            return new Promise((resolve, reject) => {
                flo({
                    baseURL: process.env.VUE_APP_FLO_BASE_URL,
                    url: "/ai/translation/",
                    method: "post",
                    timeout: 90000, // indicates, 90000ms ie. 90 second
                    headers: {
                    "Authorization": `Token ${process.env.VUE_APP_FLO_API_KEY}`,
                    "Content-type": "application/json",
                    'Cache-Control': 'no-cache'
                    },
                    data: data,
                    responseType: "json"
              }).then(response => {
                    store.commit('app/LOADING', false)
                    resolve(response)
                })
                .catch(error => reject(error))
                })
        },
        fetchVersions(ctx, queryParams ) {
            store.commit('app/LOADING', true)
            return new Promise((resolve, reject) => {
                axios
                .get(`/surveys/versions/`, {
                    params: queryParams,
                    paramsSerializer: params => transformRequestOptions(params)
                  })
                .then(response => {
                    store.commit('app/LOADING', false)
                    resolve(response)
                })
                .catch(error => reject(error))
            })
        },
        addQuestionnaireVersion(ctx, payload ) {
            store.commit('app/LOADING', true)
            return new Promise((resolve, reject) => {
                axios
                .post(`/surveys/versions/`, payload)
                .then(response => {
                    store.commit('app/LOADING', false)
                    resolve(response)
                })
                .catch(error => reject(error))
            })
        },
        
        generateQuestionnaireVersion(ctx, {id, queryParams} ) {
            return new Promise((resolve, reject) => {
                axios
                .patch(`/surveys/versions/${id}/generate/`, 
                    {/* data for the PATCH request */ }, 
                    {
                        params: queryParams,
                        paramsSerializer: params => transformRequestOptions(params),
                        responseType:'blob'
                    }
                )
                .then(response => {
                    resolve(response)
                })
                .catch(error => reject(error))
            })
        },
    }
}
