<template>
    <div>
        
        <div class="app-customizer">
            <v-btn
                icon
                class="app-customizer-toggler rounded-0"
                :class="'rounded-l-lg'"
                color="white"
                large
                @click="save()"
            >
                <v-icon size="25">
                {{ icons.mdiContentSaveOutline }}
                </v-icon>
            </v-btn>
      </div>
        <v-card v-if="localProjectData">
            <v-card-title class="pb-2">
                {{ localProjectData.job_number }} - {{ localProjectData.job_name }} - {{ localProjectData.country }}
                <v-spacer></v-spacer>
                <v-icon
                    color="error"
                    @click="deleteQuestionnaireDialogOpen = true"
                >
                {{icons.mdiTrashCanOutline }}
                </v-icon>
            </v-card-title>
            <v-dialog
                v-model="deleteQuestionnaireDialogOpen"
                width="500"
                @click:outside="deleteQuestionnaireDialogOpen = false"
                >

                <v-card>
                    <v-card-title class="text-h5 grey lighten-2">
                        Confirm
                    </v-card-title>
                    <v-card-text>
                        Are you sure you want to start from scratch?
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                            text
                            @click="deleteQuestionnaireDialogOpen = false"
                        >
                            Cancel
                        </v-btn>
                        <v-btn
                            outlined
                            color="primary"
                            :loading="deleteQuestionnaireLoading"
                            @click="deleteQuestionnaire(localProjectData.id)"
                        >
                            OK
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-card-text>
                <v-card flat>
                        <v-toolbar
                        flat
                            >

                            <v-toolbar-title>
                                <v-tabs
                                    v-model="surveyTab"
                                    align-with-title
                                    >
                                    <v-tabs-slider color="warning"></v-tabs-slider>
                                    <v-tab
                                        class="ml-0"
                                        v-if="projectPermission"
                                    >
                                        Designer
                                    </v-tab>
                                    <v-tab
                                        class="ml-0"
                                        v-if="projectPermission"
                                    >
                                        Translation
                                    </v-tab>
                                    <v-tab
                                        class="ml-0"
                                        v-if="projectPermission"
                                        @click="getVersions"
                                    >
                                        Validation
                                    </v-tab>
                                    <v-tab
                                        class="ml-0"
                                        v-if="prgmPermission"
                                    >
                                        JSON Viewer
                                    </v-tab>
                                </v-tabs>
                            </v-toolbar-title>

                            <v-spacer></v-spacer>
                            <div v-if="surveyTab == 0">
                                 
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                                    
                                        <v-btn
                                            color="primary"
                                            outlined
                                            icon
                                            class="rounded-lg mx-1"
                                            large
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="isProjectInfoDialogOpen = true"
                                        >
                                            <v-icon size="30">
                                                {{ icons.mdiInformationOutline }}
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Project Information</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                                    
                                        <v-btn
                                            color="warning"
                                            outlined
                                            icon
                                            class="rounded-lg mx-1"
                                            large
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="isQuotaDialogOpen = true"
                                            disabled
                                        >
                                            <v-icon size="30">
                                                {{ icons.mdiCartPercent }}
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Quotas</span>
                                </v-tooltip>
                                
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                                    
                                        <v-btn
                                            color="info"
                                            outlined
                                            icon
                                            class="rounded-lg mx-1"
                                            large
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="isPaperQuestionnaireDialogOpen = true"
                                        >
                                            <v-icon size="30">
                                                {{ icons.mdiFileWordOutline }}
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Download Paper Questionnaire</span>
                                </v-tooltip>
                            </div> 

                            <div v-if="surveyTab == 1">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            color="warning"
                                            outlined
                                            icon
                                            class="rounded-lg mx-1"
                                            large
                                            @click="downloadTranslation"
                                            v-bind="attrs"
                                            v-on="on">
                                            
                                            <v-icon size="30">
                                                {{ icons.mdiDownloadBoxOutline }}
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Download Translation</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                                    
                                        <v-btn
                                            color="primary"
                                            outlined
                                            icon
                                            class="rounded-lg mx-1"
                                            large
                                            :loading="isUploadSelecting"
                                            @click="onUploadClick"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <v-icon size="30">
                                                {{ icons.mdiFolderOpenOutline  }}
                                            </v-icon>
                                        </v-btn>
                                
                                        <input
                                            ref="uploader"
                                            class="d-none"
                                            type="file"
                                            accept=".csv*"
                                            @change="uploadTranslation"
                                        >
                                    </template>
                                    <span>Upload Translation</span>
                                </v-tooltip>
                                
                            
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            color="info"
                                            outlined
                                            icon
                                            class="rounded-lg mx-1"
                                            large
                                            @click="isTranslationDialogOpen = true"
                                            v-bind="attrs"
                                            v-on="on">
                                            
                                            <v-icon size="30">
                                                {{ icons.mdiTranslate }}
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Automated Translation</span>
                                </v-tooltip>
                            
                            </div>
                            <div v-if="surveyTab == 2">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            color="primary"
                                            outlined
                                            icon
                                            class="rounded-lg mx-1"
                                            large
                                            @click="validationConfirmed"
                                            v-bind="attrs"
                                            v-on="on">
                                            
                                            <v-icon size="30">
                                                {{ icons.mdiContentSaveCheckOutline }}
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Validate</span>
                                </v-tooltip>
                            
                            </div>
                            
                        </v-toolbar>
                        
                        
                        <v-tabs-items v-model="surveyTab">
                            <v-tab-item>
                                <v-card-text v-if="localProjectData.json && localProjectData.language">
                                    <v-list v-for="(section, index) in localProjectData.json.pages" 
                                        :key="index" 
                                        two-line
                                    >
                                        <v-list-item-title>
                                            <span
                                            class="step-cursor"
                                                @click="sectionClick(section, index)"
                                            >
                                                {{section.name}}
                                            </span>
                                            
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                        icon
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        @click="openQuestionType(index)"
                                                    >
                                                        <v-icon>
                                                            {{ icons.mdiPlus }}
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Add question to the {{section.name}} section</span>
                                            </v-tooltip>
                                            
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                        icon
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        @click="appendSection(index)"
                                                    >
                                                        <v-icon>
                                                            {{ icons.mdiPlaylistPlus }}
                                                        </v-icon>
                                                     
                                                    </v-btn>
                                                </template>
                                                <span>Add new section below</span>
                                            </v-tooltip>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                        icon
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        @click="deleteSection(index)"
                                                    >
                                                        <v-icon>
                                                            {{ icons.mdiTrashCanOutline }}
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Delete {{section.name}} section</span>
                                            </v-tooltip>
                                        </v-list-item-title>
                                        
                                        <draggable 
                                            :list="section.elements" 
                                            class="list-group"
                                            :animation="200"
                                            ghost-class="ghost-card"
                                            handle=".card-cursor"
                                            @end="resetQuestionNumber(index)"
                                            :scroll-sensitivity="200"
                                            :force-fallback="true"
                                        >
                                            <v-card
                                                class="mx-auto step-cursor"
                                                outlined
                                                :class="item.quota || item.statements?'alert':'regular'"
                                                v-for="(item, indexElement) in section.elements"
                                                :key="indexElement"
                                            >   
                                            <v-banner
                                                single-line
                                                height="30px"
                                                v-if="item.quota || item.statements"
                                            >
                                                <v-icon
                                                slot="icon"
                                                color="warning"
                                                small
                                                >
                                                {{ item.quota ? icons.mdiLockOpenAlertOutline : icons.mdiClipboardAlertOutline }}
                                                </v-icon>
                                                {{ item.quota ? "Don't forget to update quotas" : "Don't forget to update the different statements" }}
                                            </v-banner>
                                            
                                                <v-row dense class="justify-center">
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-icon class="card-cursor"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                            >{{icons.mdiDotsHorizontal}}</v-icon>
                                                        </template>
                                                            <span>Move Element</span>
                                                    </v-tooltip>
                                                </v-row>
                                                <div 
                                                    v-if="item.type === 'group'"
                                                >
                                                
                                                <v-list-item class="d-flex">
                                                    <v-list-item-content>
                                                        <v-card
                                                            class="mx-auto step-cursor"
                                                            outlined
                                                                v-for="(groupQuestion, indexGroupQuestion) in item.elements"
                                                                :key="indexGroupQuestion"
                                                            >
                                                            <v-list-item
                                                                class="list-group-item"
                                                            >
                                                            <!-- Uncomment if need to add option to have question being visible or not in paper questionnaire -->

                                                            <v-checkbox
                                                                v-model="groupQuestion.visible"
                                                            ></v-checkbox>
                                                                <v-list-item-content
                                                                    @click="questionClick(groupQuestion, index, indexElement, indexGroupQuestion)"
                                                                >
                                                                    <v-list-item-title>{{groupQuestion.valueName}} - {{ groupQuestion.name }}</v-list-item-title>
                                                                    
                                                                    <v-tooltip bottom v-if="groupQuestion.title">
                                                                        <template v-slot:activator="{ on, attrs }">
                                                                            <v-list-item-subtitle
                                                                                v-bind="attrs"
                                                                                v-on="on"
                                                                            >
                                                                                <span v-html="groupQuestion.title[localProjectData.language.code]"></span>
                                                                            </v-list-item-subtitle>
                                                                        </template>
                                                                        <span>{{getContentTooltip(groupQuestion.title, localProjectData.content, localProjectData.language.code)}}</span>
                                                                    </v-tooltip>
                                                                    <v-tooltip bottom v-if="groupQuestion.html">
                                                                        <template v-slot:activator="{ on, attrs }">
                                                                            <v-list-item-subtitle
                                                                                v-bind="attrs"
                                                                                v-on="on"
                                                                            >
                                                                                <span v-html="groupQuestion.html[localProjectData.language.code]"></span>
                                                                            </v-list-item-subtitle>
                                                                        </template>
                                                                        <span>{{getContentTooltip(groupQuestion.html, localProjectData.content, localProjectData.language.code)}}</span>
                                                                    </v-tooltip>
                                                                </v-list-item-content>

                                                            </v-list-item>
                                                        </v-card>
                                                    </v-list-item-content>
                                                    <v-list-item-icon class="align-self-center">
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-icon
                                                                @click="duplicateElement(item, index, indexElement)"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                >{{icons.mdiContentCopy}}</v-icon>
                                                            </template>
                                                            <span>Duplicate</span>
                                                        </v-tooltip>
                                                    </v-list-item-icon>
                                                    <v-list-item-icon class="align-self-center">
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-icon
                                                                @click="deleteElement(index, indexElement)"
                                                                v-bind="attrs"
                                                                v-on="on">{{icons.mdiDeleteOutline}}</v-icon>
                                                            </template>
                                                            <span>Delete</span>
                                                        </v-tooltip>
                                                    </v-list-item-icon>
                                                </v-list-item>
                                                
                                                </div>
                                                <div v-else>
                                                <v-list-item class="d-flex">
                                                    
                                                <!-- <v-checkbox
                                                    v-model="item.visible"
                                                ></v-checkbox> -->
                                                    <v-list-item-content
                                                        @click="questionClick(item, index, indexElement)"
                                                    >
                                                        <v-list-item-title>{{item.valueName}} - {{ item.name }}</v-list-item-title>
                                                        
                                                        <v-tooltip bottom  v-if="item.title">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-list-item-subtitle
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                >
                                                                    <span v-html="item.title[localProjectData.language.code]"></span>
                                                                </v-list-item-subtitle>
                                                            </template>
                                                            <span>{{getContentTooltip(item.title, localProjectData.content, localProjectData.language.code)}}</span>
                                                        </v-tooltip>
                                                        <v-tooltip bottom  v-if="item.html">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-list-item-subtitle
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                >
                                                                    <span v-html="item.html[localProjectData.language.code]"></span>
                                                                </v-list-item-subtitle>
                                                            </template>
                                                            <span>{{getContentTooltip(item.html, localProjectData.content, localProjectData.language.code)}}</span>
                                                        </v-tooltip>
                                                    </v-list-item-content>
                                                    <v-list-item-icon @click="duplicateElement(item, index, indexElement)" class="align-self-center">
                                                        <v-tooltip bottom >
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-icon
                                                                v-bind="attrs"
                                                                v-on="on">{{icons.mdiContentCopy}}</v-icon>
                                                            </template>
                                                            <span>Duplicate</span>
                                                        </v-tooltip>
                                                    </v-list-item-icon>
                                                    <v-list-item-icon @click="deleteElement(index, indexElement)" class="align-self-center">
                                                        <v-tooltip>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-icon
                                                                v-bind="attrs"
                                                                v-on="on">{{icons.mdiDeleteOutline}}</v-icon>
                                                            </template>
                                                            <span>Delete</span>
                                                        </v-tooltip>
                                                    </v-list-item-icon>
                                                    
                                                </v-list-item>
                                            </div>
                                            </v-card>
                                        </draggable>

                                        
                                    </v-list>
                                </v-card-text>
                                <v-card-text>
                                    <div 
                                        class="d-flex justify-center">
                                        <v-btn
                                            outlined
                                            color="info"
                                            v-if="isFromScratch"
                                            @click="openSection"
                                        >
                                            Add new section
                                            <v-icon>
                                                {{ icons.mdiPlus }}
                                            </v-icon>
                                        </v-btn>
                                        <v-dialog
                                                v-model="isNewSectionDialogOpen"
                                                width="500"
                                                @click:outside="resetNewSection"
                                                >

                                                <v-card>
                                                    <v-card-title class="text-h5 grey lighten-2">
                                                        Section Name
                                                    </v-card-title>

                                                    <v-card-text 
                                                        v-if="localProjectData.language"
                                                    >
                                                        <v-form ref="sectionForm">
                                                            
                                                            <v-text-field 
                                                                v-model="newSection.name"
                                                                label="Section"
                                                                outlined
                                                                placeholder="Screener"
                                                                hide-details="auto"
                                                                dense
                                                                class="mb-4"
                                                                :rules="[validators.required, validators.alphaDashValidator]"
                                                            ></v-text-field>
                                                            
                                                            <v-text-field 
                                                                v-model="newSection.prefix"
                                                                label="Section prefix"
                                                                outlined
                                                                placeholder="S/E_Flow/Q/C/..."
                                                                hide-details
                                                                dense
                                                                class="mb-4"
                                                                :rules="[validators.required, validators.alphaDashValidator]"
                                                            ></v-text-field>
                                                            
                                                            <v-textarea
                                                                v-model="newSection.description[localProjectData.language.code]"
                                                                label="Description"
                                                                outlined
                                                                placeholder="This is section is for..."
                                                                hide-details
                                                                dense
                                                                rows="3"
                                                                auto-grow
                                                            ></v-textarea>
                                                        </v-form>
                                                    </v-card-text>

                                                    <v-divider></v-divider>

                                                    <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                    <v-btn
                                                        text
                                                        @click="resetNewSection">
                                                        Cancel
                                                    </v-btn>
                                                    <v-btn
                                                        color="primary"
                                                        outlined
                                                        @click="addNewSection"
                                                        v-if="newSection.index === undefined"
                                                    >
                                                        Add
                                                    </v-btn>
                                                    <v-btn 
                                                        color="primary"
                                                        outlined
                                                        @click="updateSection(newSection)"
                                                        v-else
                                                    >
                                                        Update
                                                    </v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                                </v-dialog>
                                        
                                    </div>
                                    
                                </v-card-text>
                            </v-tab-item>
                            
                            <!-- Translation Tab -->
                            <v-tab-item>
                                <v-row>
                                    <v-col cols="12" md="12" sm="12">
                                        <v-card  class="overflow-x-auto">
                                            
                                            <v-card-text v-if="localProjectData.json && localProjectData.languages">
                                                <v-data-table
                                                    hide-default-header
                                                    hide-default-footer
                                                    :items-per-page="-1"
                                                    :headers="translationHeaders"
                                                    :items="localProjectData.json.pages"
                                                    dense   
                                                    >
                                                    <template v-slot:header>
                                                        <tr>
                                                            <th class="text-left">
                                                                Questions
                                                            </th>
                                                            <th class="text-left" v-for="header in localProjectData.languages" :key="header.code">
                                                                {{ header.name }}
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <td class="group-name" :colspan="localProjectData.languages.length+1">Categories</td>    
                                                        </tr>

                                                        <tr v-if="localProjectData.content.testCat">
                                                            <td>Test Category</td>
                                                            
                                                            <td class="text-left" v-for="(header, idx) in localProjectData.languages" :key="`${idx}`">
                                                                <v-textarea
                                                                    auto-grow
                                                                    rows="1"
                                                                    v-model="localProjectData.content.testCat.translation[header.code]"
                                                                    hide-details
                                                                    class="pt-0 mt-0"
                                                                    placeholder="Translation...">
                                                                </v-textarea>
                                                            </td>
                                                        </tr>
                                                        <tr v-if="localProjectData.content.clutterCat1">
                                                            <td>Clutter Category 1</td>
                                                            
                                                            <td class="text-left" v-for="(header, idx) in localProjectData.languages" :key="`${idx}`">
                                                                <v-textarea
                                                                    auto-grow
                                                                    rows="1"
                                                                    v-model="localProjectData.content.clutterCat1.translation[header.code]"
                                                                    hide-details
                                                                    class="pt-0 mt-0"
                                                                    placeholder="Translation...">
                                                                </v-textarea>
                                                            </td>
                                                        </tr>
                                                        <tr v-if="localProjectData.content.clutterCat2">
                                                            <td>Clutter Category 2</td>
                                                            
                                                            <td class="text-left" v-for="(header, idx) in localProjectData.languages" :key="`${idx}`">
                                                                <v-textarea
                                                                    auto-grow
                                                                    rows="1"
                                                                    v-model="localProjectData.content.clutterCat2.translation[header.code]"
                                                                    hide-details
                                                                    class="pt-0 mt-0"
                                                                    placeholder="Translation...">
                                                                </v-textarea>
                                                            </td>
                                                        </tr>
                                                        <tr v-if="localProjectData.content.clutterCat3">
                                                            <td>Clutter Category 3</td>
                                                            
                                                            <td class="text-left" v-for="(header, idx) in localProjectData.languages" :key="`${idx}`">
                                                                <v-textarea
                                                                    auto-grow
                                                                    rows="1"
                                                                    v-model="localProjectData.content.clutterCat3.translation[header.code]"
                                                                    hide-details
                                                                    class="pt-0 mt-0"
                                                                    placeholder="Translation...">
                                                                </v-textarea>
                                                            </td>
                                                        </tr>
                                                        <tr v-if="localProjectData.content.clutterCat4">
                                                            <td>Clutter Category 4</td>
                                                            
                                                            <td class="text-left" v-for="(header, idx) in localProjectData.languages" :key="`${idx}`">
                                                                <v-textarea
                                                                    auto-grow
                                                                    rows="1"
                                                                    v-model="localProjectData.content.clutterCat4.translation[header.code]"
                                                                    hide-details
                                                                    class="pt-0 mt-0"
                                                                    placeholder="Translation..."
                                                                    >
                                                                </v-textarea>
                                                            </td>
                                                        </tr>

                                                    </template>







                                                    
                                                    <template v-slot:item="{ item }">
                                                        
                                                        <tr v-bind:key="`${item.name}`"
                                                        >
                                                        
                                                            <td class="name-row" >{{item.name}}</td>
                                                            <td class="name-row" v-for="(header, idx) in localProjectData.languages" :key="`${idx}`">
                                                                <v-textarea
                                                                    :readonly="idx==0"
                                                                    auto-grow
                                                                    rows="1"
                                                                    hide-details
                                                                    class="pt-0 mt-0"
                                                                    v-model="item.description[header.code]"
                                                                    placeholder="Translation...">
                                                                </v-textarea>
                                                            </td>
                                                                
                                                        </tr>

                                                        <template v-for="element in item.elements">

                                                            
                                                            <template v-if="element.type == 'group'">

                                                            <template v-for="element in element.elements">
                                                            <tr v-bind:key="`${element.name}`" v-if="element.name">
                                                                <td class="group-name" :colspan="localProjectData.languages.length+1">{{ element.name }}</td>
                                                                
                                                            </tr>
                                                            <template v-for="(value, key) in textMapping">
                                                                <template v-if="element[key]">
                                                                        <tr v-bind:key="`${item.name}-${element.name}-${key}`">
                                                                            <td>{{value}}</td>
                                                                            
                                                                            <td class="text-left" v-for="(header, idx) in localProjectData.languages" :key="`${idx}`">
                                                                                
                                                                                <v-textarea
                                                                    :readonly="idx==0"
                                                                                    auto-grow
                                                                                    rows="1"
                                                                                    hide-details
                                                                                    class="pt-0 mt-0"
                                                                                    v-model="element[key][header.code]"
                                                                                    placeholder="Translation...">
                                                                                </v-textarea>
                                                                            </td>
                                                                        </tr>
                                                                    </template>
                                                            </template>
                                                            <template v-if="element.choices">
                                                                <tr v-bind:key="`${element.name}-choices`">
                                                                    <td class="choice-name" :colspan="localProjectData.languages.length+1">Choices</td>
                                                                    
                                                                </tr>
                                                                <template v-for="(choice) in element.choices">
                                                                
                                                                    <tr v-bind:key="`${element.name}-choices-${choice.value}`">
                                                                        <td>{{ choice.value }}</td>
                                                                        <td class="text-left" v-for="(header, idx) in localProjectData.languages" :key="`${idx}`">
                                                                            <v-textarea
                                                                    :readonly="idx==0"
                                                                                auto-grow
                                                                                rows="1"
                                                                                hide-details
                                                                                class="pt-0 mt-0"
                                                                                v-model="choice.text[header.code]"
                                                                                placeholder="Translation...">
                                                                            </v-textarea>
                                                                            
                                                                        </td>
                                                                    </tr>
                                                                </template>
                                                            </template>
                                                            <template v-if="element.themes">
                                                                <tr v-bind:key="`${element.name}-themes`">
                                                                    <td class="choice-name" :colspan="localProjectData.languages.length+1">Themes</td>
                                                                    
                                                                </tr>
                                                                <template v-for="(theme, themeIdx) in element.themes">
                                                                    
                                                                    <tr v-bind:key="`${element.name}-themes-${themeIdx}`">
                                                                        <td>Theme</td>
                                                                        
                                                                        <td class="text-left" v-for="(header, idx) in localProjectData.languages" :key="`${idx}`">
                                                                        <v-textarea
                                                                    :readonly="idx==0"
                                                                            auto-grow
                                                                            rows="1"
                                                                            hide-details
                                                                            class="pt-0 mt-0"
                                                                            v-model="theme.title[header.code]"
                                                                            placeholder="Translation...">
                                                                        </v-textarea>
                                                                        </td>
                                                                    </tr>
                                                                <template v-for="(themeRow, themeRowIdx) in theme.rows">
                                                                        <tr v-bind:key="`${element.name}-themes-${themeIdx}-row-${themeRowIdx}`">
                                                                            <td>{{ themeRow.value }}</td>
                                                                            <td class="text-left" v-for="(header, idx) in localProjectData.languages" :key="`${idx}`">
                                                                                <v-textarea
                                                                    :readonly="idx==0"
                                                                                    auto-grow
                                                                                    rows="1"
                                                                                    hide-details
                                                                                    class="pt-0 mt-0"
                                                                                    v-model="themeRow.text[header.code]"
                                                                                    placeholder="Translation...">
                                                                                </v-textarea>
                                                                                
                                                                            </td>
                                                                        </tr>
                                                                    </template>
                                                                </template>
                                                            </template>
                                                            
                                                            <template v-if="element.columns">
                                                                <tr v-bind:key="`${element.name}-columns`">
                                                                    <td class="choice-name" :colspan="localProjectData.languages.length+1">Columns</td>
                                                                    
                                                                </tr>
                                                                <template v-for="(column) in element.columns">
                                                                    <tr v-bind:key="`${element.name}-columns-${column.value}`" v-if="column.value">
                                                                        <td>{{ column.value }}</td>
                                                                        <td class="text-left" v-for="(header, idx) in localProjectData.languages" :key="`${idx}`">
                                                                            <v-textarea
                                                                    :readonly="idx==0"
                                                                                auto-grow
                                                                                rows="1"
                                                                                hide-details
                                                                                class="pt-0 mt-0"
                                                                                v-model="column.text[header.code]"
                                                                                placeholder="Translation...">
                                                                            </v-textarea>
                                                                            
                                                                        </td>
                                                                    </tr>
                                                                    <tr v-bind:key="`${element.name}-columns-${column.name}`" v-else>
                                                                        <td>{{ column.name }}</td>
                                                                        <td class="text-left" v-for="(header, idx) in localProjectData.languages" :key="`${idx}`">
                                                                            <v-textarea
                                                                    :readonly="idx==0"
                                                                                auto-grow
                                                                                rows="1"
                                                                                hide-details
                                                                                class="pt-0 mt-0"
                                                                                v-model="column.title[header.code]"
                                                                                placeholder="Translation...">
                                                                            </v-textarea>
                                                                            
                                                                        </td>
                                                                    </tr>
                                                                </template>
                                                            </template>
                                                            
                                                            <template v-if="element.rows">
                                                                <tr v-bind:key="`${element.name}-rows`">
                                                                    <td class="choice-name" :colspan="localProjectData.languages.length+1">Rows</td>
                                                                    
                                                                </tr>
                                                                <template v-for="(row) in element.rows">
                                                                    <tr v-bind:key="`${element.name}-rows-${row.value}`">
                                                                        <td>{{ row.value }}</td>
                                                                        <td class="text-left" v-for="(header, idx) in localProjectData.languages" :key="`${idx}`">
                                                                            <v-textarea
                                                                                :readonly="idx==0"
                                                                                auto-grow
                                                                                rows="1"
                                                                                hide-details
                                                                                class="pt-0 mt-0"
                                                                                v-model="row.text[`${header.code}`]"
                                                                                placeholder="Translation...">
                                                                            </v-textarea>
                                                                            
                                                                        </td>
                                                                    </tr>
                                                                </template>
                                                            </template>
                                                            </template>





























                                                            </template>
                                                            <template v-else>
                                                                <tr v-bind:key="`${element.name}`" v-if="element.name">
                                                                <td class="group-name" :colspan="localProjectData.languages.length+1">{{ element.name }}</td>
                                                                
                                                            </tr>
                                                            <template v-for="(value, key) in textMapping">
                                                                <template v-if="element[key]">
                                                                        <tr v-bind:key="`${item.name}-${element.name}-${key}`">
                                                                            <td>{{value}}</td>
                                                                            
                                                                            <td class="text-left to-update" v-for="(header, idx) in localProjectData.languages" :key="`${idx}`">
                                                                                <v-textarea
                                                                                    :readonly="idx==0"
                                                                                    auto-grow
                                                                                    rows="1"
                                                                                    hide-details
                                                                                    class="pt-0 mt-0"
                                                                                    v-model="element[key][header.code]"
                                                                                    placeholder="Translation...">
                                                                                </v-textarea>
                                                                            </td>
                                                                        </tr>
                                                                    </template>
                                                                    
                                                            </template>
                                                            <template v-if="element.choices">
                                                                <tr v-bind:key="`${element.name}-choices`">
                                                                    <td class="choice-name" :colspan="localProjectData.languages.length+1">Choices</td>
                                                                    
                                                                </tr>
                                                                <template v-for="(choice) in element.choices">
                                                                
                                                                    <tr v-bind:key="`${element.name}-choices-${choice.value}`">
                                                                        <td>{{ choice.value }}</td>
                                                                        <td class="text-left" v-for="(header, idx) in localProjectData.languages" :key="`${idx}`">
                                                                            <v-textarea
                                                                    :readonly="idx==0"
                                                                                auto-grow
                                                                                rows="1"
                                                                                hide-details
                                                                                class="pt-0 mt-0"
                                                                                v-model="choice.text[header.code]"
                                                                                placeholder="Translation...">
                                                                            </v-textarea>
                                                                            
                                                                        </td>
                                                                    </tr>
                                                                </template>
                                                            </template>
                                                            <template v-if="element.themes">
                                                                <tr v-bind:key="`${element.name}-themes`">
                                                                    <td class="choice-name" :colspan="localProjectData.languages.length+1">Themes</td>
                                                                    
                                                                </tr>
                                                                <template v-for="(theme, themeIdx) in element.themes">
                                                                    
                                                                    <tr v-bind:key="`${element.name}-themes-${themeIdx}`">
                                                                        <td>Theme</td>
                                                                        <td class="text-left" v-for="(header, idx) in localProjectData.languages" :key="`${idx}`">
                                                                        <v-textarea
                                                                    :readonly="idx==0"
                                                                            auto-grow
                                                                            rows="1"
                                                                            hide-details
                                                                            class="pt-0 mt-0"
                                                                            v-model="theme.title[header.code]"
                                                                            placeholder="Translation...">
                                                                        </v-textarea>
                                                                    </td>
                                                                    </tr>
                                                                <template v-for="(themeRow, themeRowIdx) in theme.rows">
                                                                        <tr v-bind:key="`${element.name}-themes-${themeIdx}-row-${themeRowIdx}`">
                                                                            <td>{{ themeRow.value }}</td>
                                                                            <td class="text-left" v-for="(header, idx) in localProjectData.languages" :key="`${idx}`">
                                                                                <v-textarea
                                                                                    :readonly="idx==0"
                                                                                    auto-grow
                                                                                    rows="1"
                                                                                    hide-details
                                                                                    class="pt-0 mt-0"
                                                                                    v-model="themeRow.text[header.code]"
                                                                                    placeholder="Translation...">
                                                                                </v-textarea>
                                                                                
                                                                            </td>
                                                                        </tr>
                                                                    </template>
                                                                </template>
                                                            </template>
                                                            
                                                            <template v-if="element.columns">
                                                                <tr v-bind:key="`${element.name}-columns`">
                                                                    <td class="choice-name" :colspan="localProjectData.languages.length+1">Columns</td>
                                                                    
                                                                </tr>
                                                                <template v-for="(column) in element.columns">
                                                                    <tr v-bind:key="`${element.name}-columns-${column.value}`" v-if="column.value">
                                                                        <td>{{ column.value }}</td>
                                                                        <td class="text-left" v-for="(header, idx) in localProjectData.languages" :key="`${idx}`">
                                                                            <v-textarea
                                                                    :readonly="idx==0"
                                                                                auto-grow
                                                                                rows="1"
                                                                                hide-details
                                                                                class="pt-0 mt-0"
                                                                                v-model="column.text[header.code]"
                                                                                placeholder="Translation...">
                                                                            </v-textarea>
                                                                        </td>
                                                                    </tr>
                                                                    <tr v-bind:key="`${element.name}-columns-${column.name}`" v-else>
                                                                        <td>{{ column.name }}</td>
                                                                        <td class="text-left" v-for="(header, idx) in localProjectData.languages" :key="`${idx}`">
                                                                            <v-textarea
                                                                    :readonly="idx==0"
                                                                                auto-grow
                                                                                rows="1"
                                                                                hide-details
                                                                                class="pt-0 mt-0"
                                                                                v-model="column.title[header.code]"
                                                                                placeholder="Translation...">
                                                                            </v-textarea>
                                                                        </td>
                                                                    </tr>
                                                                </template>
                                                            </template>
                                                            
                                                            <template v-if="element.rows">
                                                                <tr v-bind:key="`${element.name}-rows`">
                                                                    <td class="choice-name" :colspan="localProjectData.languages.length+1">Rows</td>
                                                                    
                                                                </tr>
                                                                <template v-for="(row) in element.rows">
                                                                    <tr v-bind:key="`${element.name}-rows-${row.value}`">
                                                                        <td>{{ row.value }}</td>
                                                                        <td class="text-left" v-for="(header, idx) in localProjectData.languages" :key="`${idx}`">
                                                                            <v-textarea
                                                                                :readonly="idx==0"
                                                                                auto-grow
                                                                                rows="1"
                                                                                hide-details
                                                                                class="pt-0 mt-0"
                                                                                v-model="row.text[`${header.code}`]"
                                                                                placeholder="Translation...">
                                                                            </v-textarea>
                                                                        </td>
                                                                    </tr>
                                                                </template>
                                                            </template>

                                                            </template>

                                                            
                                                        </template>
                                                    </template>

                                                </v-data-table>
                                            
                                        </v-card-text>
                                        </v-card>
                                    </v-col>
                                    
                                </v-row>
                                
                                
                                
                                
                            </v-tab-item>
                            <v-tab-item>
                                <v-alert
                                    v-model="translationAlert"
                                    dense
                                    outlined
                                    type="error"
                                >
                                    {{ alertText }}
                                </v-alert>
                                <questionnaire-version
                                    :versions="versionsItems"
                                    :questionnaire-id="localProjectData.id"
                                    :loading="versionsLoading"

                                >
                                    
                                </questionnaire-version>
                            </v-tab-item>
                            <v-tab-item
                            v-if="prgmPermission">
                                <app-card-code
                                    title="Project Info"
                                    :code="JSON.stringify(localProjectData, null, 2)"
                                    codeLanguage="javascript"
                                    :isCodeCollapsed="true"
                                    >
                                </app-card-code>
                            </v-tab-item>
                            
                        </v-tabs-items>
                        
                    </v-card>
                    
            </v-card-text>
            
        </v-card>
        
        <v-navigation-drawer
            v-model="isQuestionDrawerActive"
            touchless
            app
            right
            width="720"
            class="overlay"
            temporary
        >
            <question-drawer-content
                :is-active="isQuestionDrawerActive"
                :question="question"
                :content="localProjectData.content"
                :defaultLang="localProjectData.language"
                :question-label-list="questionLabelList"
                :questions-with-choices="questionsWithChoices"
                :variable-suggestions="variableSuggestions"
                :collection-method="localProjectData.collection_method"
                @update-item="updateQuestion"
                v-if="localProjectData.language"
            >

            </question-drawer-content>
        </v-navigation-drawer>
        <question-type
            :isDialogVisible.sync="isQuestionTypeDialog"
            :index-page="indexPage"
            :question-label-list="questionLabelList"
            :defaultLang="localProjectData.language"
            :questions-with-choices="questionsWithChoices"
            :variable-suggestions="variableSuggestions"
            :cell-count="localProjectData.content.cellCount"
            @add-element="addElement"
            v-if="localProjectData.language"
        >

        </question-type>
        <translation-dialog-edit
            :is-translation-dialog-open.sync="isTranslationDialogOpen"
            :project-data="localProjectData"
            :languageOptions="languageOptions"
            :translationLoading="translationLoading"
            @translate="aiTranslation"
        ></translation-dialog-edit>
        
        <paper-questionnaire-dialog
            :is-paper-questionnaire-dialog-open.sync="isPaperQuestionnaireDialogOpen"
            :languageOptions="localProjectData.languages"
            :loading="exportLoading"
            @export="download"
            v-if="localProjectData.languages"
        ></paper-questionnaire-dialog>
        <supplemental-info-dialog
            :is-dialog-visible.sync="isProjectInfoDialogOpen"
            :project-data="localProjectData"
            :language-options="languageOptions"
            :language-loading="languageLoading"
            @save="save"
        >
        </supplemental-info-dialog>
        
        <quota-dialog
            :is-quota-dialog-open.sync="isQuotaDialogOpen"
            :project-data="localProjectData"
        >
        </quota-dialog>
        <piping-var-dialog
            :is-piping-var-dialog-open.sync="isPipingVarDialogOpen"
            :pipe="pipingVar"
            @update="updatePipe"
        >

        </piping-var-dialog>
        <v-dialog
            v-model="validationConfirmation"
            persistent
            max-width="450"
            >
            <v-card>
                <v-card-title class="text-h5">
                    Confirmation
                </v-card-title>
                <v-card-text>
                    Have you received validation and confirmation from the client on this questionnaire?
                </v-card-text>
                <v-card-text v-if="versionsItems.filter(version => version.questionnaire == localProjectData.id).length > 0">
                    <v-form
                        ref="versionConfirmForm"
                    >
                        <span>We've noticed you are about to validate a questionnaire that has been validated before, please add a note to inform the prgm and PM teams on what has changed!</span>
                        <v-textarea
                            v-model="versionNotes"
                            outlined
                            label="Notes"
                            :rules="[validators.required]"
                            class="pt-2"
                        >
                            
                        </v-textarea>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    @click="validationConfirmation = false"
                >
                    Cancel
                </v-btn>
                <v-btn
                    color="success"
                    text
                    @click="BuildQuestionnaire"
                >
                    Yes
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>


    </div>
</template>

<script>
import { ref, watch, onMounted, onUnmounted, getCurrentInstance } from '@vue/composition-api'
import { 
    mdiHeart, 
    mdiShareVariant, 
    mdiPencil, 
    mdiTrashCanOutline, 
    mdiPlus,
    mdiContentCopy,
    mdiTextBoxEditOutline,
    mdiSetSplit,
    mdiFormatAlignJustify,
    mdiDeleteOutline,
    mdiDotsHorizontal,
    mdiContentSaveOutline,
    mdiDownloadBoxOutline,
    mdiFolderOpenOutline,
    mdiInformationOutline,
    mdiFileWordOutline,
    mdiTranslate,
    mdiPlaylistPlus,
    mdiContentSaveCheckOutline,
    mdiLockOpenAlertOutline,
    mdiClipboardAlertOutline,
    mdiCartPercent
} from '@mdi/js'
import store from '@/store'
import router from '@/router'

import {
  required,
  requiredArray,
  alphaDashValidator,
  integerValidator,
  billingRuleValidator
} from '@core/utils/validation'
import surveyStoreModule from '../surveyStoreModule'
import useSurvey from '../useSurvey'

import ListItemDialog from './ListItemDialog.vue'
import QuestionDrawerContent from './questions/QuestionDrawerContent.vue'
import QuestionType from './questions/QuestionType.vue'
import TranslationDialogEdit from './translation/TranslationDialogEdit.vue'
import PaperQuestionnaireDialog from './PaperQuestionnaireDialog.vue'
import SupplementalInfoDialog from './supplemental/SupplementalInfoDialog.vue'
import QuotaDialog from './quotas/QuotaDialog.vue'
import PipingVarDialog from './supplemental/PipingVarDialog.vue'
import QuestionnaireVersion from './versions/QuestionnaireVersion.vue'
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'
import Draggable from "vuedraggable"

export default {
    template: '#table-template',
    components:{
        AppCardCode,
        Draggable,
        ListItemDialog,
        QuestionDrawerContent,
        QuestionType,
        TranslationDialogEdit,
        PaperQuestionnaireDialog,
        SupplementalInfoDialog,
        PipingVarDialog,
        QuestionnaireVersion,
        QuotaDialog
    },
    setup() {
  
        const vm = getCurrentInstance().proxy
        const SURVEY_APP_STORE_MODULE_NAME = 'app-survey'

        // Register module
        if (!store.hasModule(SURVEY_APP_STORE_MODULE_NAME)) store.registerModule(SURVEY_APP_STORE_MODULE_NAME, surveyStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
        if (store.hasModule(SURVEY_APP_STORE_MODULE_NAME)) store.unregisterModule(SURVEY_APP_STORE_MODULE_NAME)
        })

        const localProjectData = ref({})
        const isQuestionDrawerActive = ref(false)
        const question = ref({})

        const isQuestionTypeDialog = ref(false)
        const isTranslationDialogOpen = ref(false)
        const isPaperQuestionnaireDialogOpen = ref(false)
        const isProjectInfoDialogOpen = ref(false)
        const isQuotaDialogOpen = ref(false)
        const deleteQuestionnaireDialogOpen = ref(false)
        const isNewSectionDialogOpen = ref(false)
        const newSection = ref({})

        const pipingCheck = ref([])
        const pipingVar = ref({})
        const isPipingVarDialogOpen = ref(false)

        const alertText = ref('')
        

        const isFromScratch = ref(false)

        const versionNotes = ref("")
        const validationConfirmation = ref(false)

        const resetSection = () => {
            if (localProjectData.value.language){
                newSection.value = {
                    name: '',
                    prefix: '',
                    description: {[localProjectData.value.language.code]: ''}
                }
            } else {
                newSection.value = {
                    name: '',
                    prefix: '',
                    description: {en: ''}
                }
            }
            
        }
        
        const projectPermission = vm.$ability.can('read', 'project')
        const prgmPermission = vm.$ability.can('update', 'forsta')

        const projectSampleOverview = ref([
            {label: "Behavioral Metrics", sample: []},
            {label: "Pack Eyeflow", sample: []},
            {label: "Flash Exposure", sample: []},
            {label: "System 2 Communication", sample: []}
        ])

        const isDialogOpen = ref(false)
        const titleDialog = ref("")
        const stateDialog = ref("")
        const itemList = ref("")
        const indexPage = ref(0)
        const indexElement = ref(0)
        const indexGroupElement = ref(null)
        const questionLabelList = ref(new Set())
        const questionsWithChoices = ref([])
        const translationAlert = ref(false)

        const surveyTab = ref(null)
        const surveyTabItems = ref([
          {text: 'Designer', display:projectPermission},
          {text: 'Translation', display:projectPermission},
          {text: 'JSON Viewer', display:prgmPermission},
          {text: 'Versions', display:projectPermission}
        ])

        const variableSuggestions = ref([])
        

        const isUploadSelecting = ref(false)

        const {
            projectData,
            fetchProject,
            exportLoading,
            downloadQuestionnaire,
            updateQuestionnaire,
            deleteQuestionnaire,
            getContentTooltip,
            textMapping,
            languageOptions,
            languageLoading,
            fetchLanguages,
            exportToCSVFile,
            importFromCSVFile,
            translateViaAzureTranslator,
            translationLoading,
            deleteQuestionnaireLoading,
            addQuestionnaireVersion,
            listQuestionLabel,
            splitIfStartsWithPrefix,

            fetchVersions,
            versionsItems,
            versionsLoading
        } = useSurvey()


        const isObjectEmpty = (objectName) => {
            return (
                objectName &&
                Object.keys(objectName).length === 0 &&
                objectName.constructor === Object
            )
        }

        
        const searchJSON = (obj, prefix, keySearch) => {
            const results = [];

            const search = (obj) => {
                for (const key in obj) {
                const value = obj[key];

                if (typeof value === 'object' && value !== null) {
                    search(value); 
                } else if (typeof value === 'string' && value.startsWith(prefix) && key == keySearch) {
                    results.push(value);
                }
                }
            }

            search(obj);
            return results;
        }
            

        const normalizeProjectData = (projectData) => {
            var content = {
                clutterCat1: "",
                clutterCat2: "",
                clutterCat3: "",
                clutterCat4: "",
                testCat: "",
                brand: projectData["brand"],
                usageTime: 0,
                cellCount:projectData.phase,
                price: (new Array(projectData.phase).fill(0)),
                competitor: (new Array(projectData.phase).fill(null)),
                sample_findability: (new Array(projectData.phase).fill(0)),
                testProduct: (new Array(projectData.phase).fill(null)),
                sample_split: (new Array(projectData.phase).fill(0)),
                sample_flash: (new Array(projectData.phase).fill(0)),
                sample_ef: (new Array(projectData.phase).fill(0)),
                finda: (new Array(projectData.phase).fill(null)),
                phases:(new Array(projectData.phase).fill(null))
            }

            if (projectData.methodology == 'innocompass') {
                content['ad'] = false
                content['concept'] = false
            }
            
            if (!isObjectEmpty(projectData.content)) {
                content = projectData.content
                // Object.entries(content).forEach(([key, value]) => {
                //     if (projectData.content[key] !== undefined) content[key] = projectData.content[key]
                // })
            }
            if (content.cellCount === undefined) content.cellCount = projectData.phase
            
            const findaMatches = searchJSON(projectData.json.pages, 'finda', 'pipe')
            findaMatches.forEach(findaLabel => {
                    if (content[findaLabel] == undefined) {
                        content[findaLabel] = new Array(content.cellCount).fill(null)
                    }
                })
            // for (const [key, value] of Object.entries(content)) {
            //     console.log(key, Boolean(projectData.content[key]))
            //     if (!projectData.content[key] != null) projectData.content[key] = value
            // }
            
            if (content.testCat){
                variableSuggestions.value = [
                    {label:"testProduct", value:projectData.content['testProduct']},
                    {label:"competitor", value:projectData.content['competitor']},
                    {label:"brand", value:projectData.content['brand']},
                    {label:"testCat", value:projectData.content['testCat'].translation[projectData.language.code]},
                    {label:"usageTime", value:projectData.content['usageTime']},
                    {label:"clutterCat1", value:projectData.content['clutterCat1'].translation[projectData.language.code]},
                    {label:"clutterCat2", value:projectData.content['clutterCat2'].translation[projectData.language.code]},
                    {label:"clutterCat3", value:projectData.content['clutterCat3'].translation[projectData.language.code]},
                    {label:"clutterCat4", value:projectData.content['clutterCat4'].translation[projectData.language.code]},
                    {label:"price", value:projectData.content['price']}
                ]
                
                const finda = Object.keys(projectData.content).filter((element) => element.startsWith('finda'))
                finda.forEach(findaLabel => {
                    variableSuggestions.value.push({
                        label:findaLabel, value:projectData.content[findaLabel]
                    })
                })
                
            }
            



            var langTranslations = []

            for (var lang of projectData.translations){
                langTranslations.push({
                    id: lang.language.id,
                    code: lang.language.code,
                    name: lang.language.name
                })
            }
            projectData['translations'] = langTranslations
            // projectData['languages'] = languageOptions.value.filter(language => {
            //     return language.id == projectData.language
            // })
            projectData['languages'] = []
            projectData['languages'].push(projectData['language'], ...langTranslations)

            
            return {
                ...projectData,
                content,
            }
        }


        const updateItemList = (itemList) => {
            
            store.commit(`app-survey/${stateDialog.value}`, itemList)
        }

        const download = (lang) => {
            // const content = localProjectData.value['content']
            // // delete localProjectData.value['content']
            // localProjectData.value = {
            //     ...localProjectData.value,
            //     ...content
            // }
            localProjectData.value['export_lang'] = lang.map(language => language.code)
            downloadQuestionnaire(localProjectData.value)
        }
        const save = (data) => {
            // const content = localProjectData.value['content']
            // // delete localProjectData.value['content']
            // localProjectData.value = {
            //     ...localProjectData.value,
            //     ...content
            // }
            if (data === undefined) data = localProjectData
            //Reset Translation object
            if (data.value.translationUpdated){
                
                var translations = []
                for (var lang of data.value.translations){
                    translations.push({
                        language: lang.id
                    })
                }
                data.value['quest_translations'] = translations
            }
            
            
            // if (typeof data.value['language'] === 'object') data.value['language'] = data.value['language'].id
            
            updateQuestionnaire(data.value)
            isProjectInfoDialogOpen.value = false
        }
        
        

        const updateQuestion = (q) => {
            if (indexGroupElement.value == null){
                localProjectData.value.json.pages[indexPage.value].elements[indexElement.value] = q
                save(localProjectData)
            } else {
                localProjectData.value.json.pages[indexPage.value].elements[indexElement.value].elements[indexGroupElement.value] = q
                save(localProjectData)
            }
            // isQuestionDrawerActive.value = false
        }

        const questionClick = (q, page, element, groupIndex=null) => {
            questionLabelList.value = new Set(listQuestionLabel(localProjectData.value.json.pages))
            questionLabelList.value.delete(splitIfStartsWithPrefix(q.valueName, "QX_"))
            questionLabelList.value.delete(splitIfStartsWithPrefix(q.name, "QX_"))


            questionsWithChoices.value = listQuestionsWithChoices(q.name)

            isQuestionDrawerActive.value = true
            question.value = q
            indexPage.value = page
            indexElement.value = element
            indexGroupElement.value = groupIndex

        }

        const openQuestionType = (index) => {
            questionLabelList.value = new Set(listQuestionLabel(localProjectData.value.json.pages))
            indexPage.value = index
            questionsWithChoices.value = listQuestionsWithChoices('')
            isQuestionTypeDialog.value = true
        }

        

        const listQuestionsWithChoices = (name) => {
            return localProjectData.value.json.pages.map(page => {
                if (page['elements'] !== undefined) return page.elements.map(element => {
                    if (element.choices !== undefined && element.name != name) return [element.name]
                    if (element.rows !== undefined && element.name != name) return [element.name]
                    if (element.type == 'group'){
                        return element.elements.map(question => {
                            if(question.choices !== undefined && question.name != name) return [question.name]
                            if(question.rows !== undefined && question.name != name) return [question.name]
                            return []
                        })
                    }
                    return []
                })
                return []
            }).flat(3).filter(function( element ) {
                return element !== undefined;
            })
        }

        const addElement = (index, question) => {
            if (index != null && question){
                if ('elements' in localProjectData.value.json.pages[index]) {
                    localProjectData.value.json.pages[index].elements.push(question.element)
                } else {
                    
                    localProjectData.value.json.pages[index]['elements'] = [question.element]
                } 
            }
            isQuestionTypeDialog.value = false
            resetQuestionNumber(index)
            save(localProjectData)

        }

        const duplicateElement = (element, indexPage, index) => {
            const localElement = JSON.parse(JSON.stringify(element))

            if (localElement.pipe !== undefined && localElement.pipe !== ''){
                localElement.pipe = ''
            }
            
            questionLabelList.value = new Set(listQuestionLabel(localProjectData.value.json.pages))
            let dupName = false
            let indexLabel = 1
            while(!dupName){
                localElement.name = `QX_${element.name}_${indexLabel}`
                if([...questionLabelList.value].filter(element => element == localElement.name).length == 0){
                    dupName = true
                } else {
                    indexLabel++
                }
            }

            if(localElement.type == 'group'){
                localElement.elements.forEach(question => {
                    dupName = false
                    indexLabel = 1
                    let name = `QX_${question.name}_${indexLabel}`
                    while(!dupName){
                        name = `QX_${question.name}_${indexLabel}`
                        if([...questionLabelList.value].filter(element => element == name).length == 0){
                            dupName = true
                        } else {
                            indexLabel++
                        }
                    }
                    question.name = name
                })
            }


            // localElement.name = `Q${questionIndex.value++}`
            localProjectData.value.json.pages[indexPage].elements.splice(index+1,0,localElement)
            save(localProjectData)
        }

        const deleteElement = (indexPage, index) => {
            const element = localProjectData.value.json.pages[indexPage].elements[index]
            
            if (element.pipe !== undefined & element.pipe !== '') delete localProjectData.value.content[element.pipe]
            const list = localProjectData.value.json.pages[indexPage].elements
            const newList = [
                ...list.slice(0, index),
                ...list.slice(index + 1, list.length)
            ]
            
            localProjectData.value.json.pages[indexPage].elements = newList
            
        }

        const translationHeaders = ref([
            
            { 
                text: 'Questions',
                value: 'name',
            },
        ])

        

        const editTranslation = (value, item, key, lang) => {
            // if (typeof item[key] == 'object'){
            //     item[key][lang] = value
            // } else if (typeof item[key] == 'string' && lang != 'default') {
            //     item[key] = {
            //         default: item[key]
            //     }
            //     item[key][lang] = value
            // } else {
            //     item[key] = value
            // }
            
            console.log(item[key])
        }

        const downloadTranslation = () => {
            let locales = localProjectData.value.languages.map(lang => lang.code)
            exportToCSVFile(`${localProjectData.value.job_number} - ${localProjectData.value.job_name} - Translation.csv`, localProjectData.value.json.pages, locales);
        }

        const uploadTranslation = (el) => {
            if (el.target.files.length < 1) return;
            importFromCSVFile(localProjectData.value.json.pages,el.target.files[0]);
            console.log('The file has been uploaded successfully.');
        }

        const onUploadClick = () =>{
            isUploadSelecting.value = true
            window.addEventListener('focus', () => {
                isUploadSelecting.value = false
            }, { once: true })

            vm.$refs.uploader.click()
        }

        const aiTranslation = (data) => {
            
            const translateAll = data.translationOptions == '1'
            // localProjectData.value['language'] = localProjectData.value['language'].id
            // translateViaAI(localProjectData.value, localProjectData.value.language.code, data.to.code, translateAll)
            translateViaAzureTranslator(localProjectData.value, data.from.code, data.to.code, translateAll)
        }

        const isSectionFormValid = () => {
            const isValid = vm.$refs.sectionForm.validate()
            return isValid
        }

        const addNewSection = () => {
            const isValid = isSectionFormValid()
            if (isValid){
                if (isObjectEmpty(projectData.value.content)) {
                    isProjectInfoDialogOpen.value = true
                } else {
                    const section = {
                        name: newSection.value.name,
                        prefix: newSection.value.prefix,
                        title: {[localProjectData.value.language.code]:newSection.value.name},
                        description: newSection.value.description,
                        elements:[]
                    }
                    if (newSection.value.prevIndex != undefined || newSection.value.prevIndex >= 0) localProjectData.value.json.pages.splice(newSection.value.prevIndex+1, 0, section)
                    else localProjectData.value.json.pages.push(section)
                    isNewSectionDialogOpen.value = false
                    resetSection()
                }
            }
            return
        }
        const resetNewSection = () => {
            resetSection()
            isNewSectionDialogOpen.value = false
        }

        const deleteSection = index => {
            localProjectData.value.json.pages.splice(index, 1)
        }

        const appendSection = (index) => {
            
            resetNewSection()
            newSection.value['prevIndex'] = index 
            isNewSectionDialogOpen.value = true

        }
        const openSection = () => {
            resetSection()
            isNewSectionDialogOpen.value = true
        }

        const updateSection = (section) => {
            const isValid = isSectionFormValid()
            if (isValid){
                const index = section.index
                if (index !== undefined) {
                    localProjectData.value.json.pages[index].name = section.name
                    localProjectData.value.json.pages[index].prefix = section.prefix
                    localProjectData.value.json.pages[index].description = section.description
                    resetQuestionNumber(index)
                }
                resetNewSection()
                save()
            }
            return
        }

        const sectionClick = (section, index) => {
            newSection.value = {
                name: section.name,
                description: section.description,
                prefix: section.prefix,
                index: index
            }
            
            isNewSectionDialogOpen.value = true
        }

        const resetQuestionNumber = (index) => {
            const elements = localProjectData.value.json.pages[index].elements;
            const prefix = localProjectData.value.json.pages[index].prefix
            if (prefix !== undefined && prefix !== null){
                let iter = 1
                elements.map((element) => {
                    if (element.type == 'group'){
                        element.elements.map((question) => {
                            question.valueName = `${prefix}_${iter}`
                            iter++
                            return question
                        })
                    } else {
                        element.valueName = `${prefix}_${iter}`
                        iter++
                    }
                    return element
                })
            }

        }

        const checkPipingVar = (data) => {

            data.json.pages.forEach((page, pageIdx) => {
                page.elements.forEach((element, elementIdx) => {
                    if (element.piping_var_prefix !== undefined){
                        if (element.pipe === undefined || element.pipe === ''){
                            const pipe = {
                                cellCount: data.content.cellCount,
                                question: element.class || element.name,
                                id: null,
                                prefix: element.piping_var_prefix,
                                value: [],
                                sectionIndex: pageIdx,
                                elementIndex:elementIdx

                            }
                            pipingCheck.value.push(pipe)
                        }
                    }
                })
            })

        }
        
        const updatePipe = (pipe) => {
            let prefix = Object.keys(localProjectData.value.content).filter((element) => element.startsWith(pipe.prefix));
            let dupName = false
            let iteration = 1
            let id = null

            while(!dupName){
                id = `${pipe.prefix}_${iteration}`
                if([...prefix].filter(element => element == id).length == 0){
                    if(localProjectData.value.content[pipe.prefix] == undefined && iteration == 1) id = pipe.prefix
                    dupName = true
                } else {
                    iteration++
                }
            }
            localProjectData.value.content[id] = pipe.value
            localProjectData.value.json.pages[pipe.sectionIndex].elements[pipe.elementIndex].pipe = id
            const reg = /{finda.*?}/gi

            for (const [key, value] of Object.entries(localProjectData.value.json.pages[pipe.sectionIndex].elements[pipe.elementIndex].elements)) {
                if (value.title) Object.keys(value.title).forEach(lang => value.title[lang] = value.title[lang].replace(reg, `{${id}}`))
                if (value.description) Object.keys(value.description).forEach(lang => value.description[lang] = value.description[lang].replace(reg, `{${id}}`))
                if (value.html) Object.keys(value.html).forEach(lang => value.html[lang] = value.html[lang].replace(reg, `{${id}}`))
            }


            isPipingVarDialogOpen.value = false
            pipingCheck.value.pop()
            save(localProjectData)
        }


        const BuildQuestionnaire = () => {
            var isValid = true
            if (vm.$refs.versionConfirmForm != undefined){
                isValid = vm.$refs.versionConfirmForm.validate()
            }
            
            if (!isValid) return

            
            validationConfirmation.value = false
            const notes = versionsItems.value.length > 0 ? versionNotes.value : "Initial Validation"
                
            addQuestionnaireVersion({questionnaire:localProjectData.value.id, notes}, versionsItems.value)

        }

        const validationConfirmed = () => {
            alertText.value = ''
            const rcda = searchJSON(localProjectData.value.json.pages, 'rcda_Inst', 'name')
            if (rcda.length == 0) {
                alertText.value = `The RCDA text is missing. `
            }


            // const notTranslatedPages = findEmtpyTranslation(localProjectData.value.json.pages)
            // const notTranslatedTestCat = findEmtpyTranslation(localProjectData.value.content.testCat.translation)
            // const notTranslatedClutterCat1 = findEmtpyTranslation(localProjectData.value.content.clutterCat1.translation)
            // const notTranslatedClutterCat2 = findEmtpyTranslation(localProjectData.value.content.clutterCat2.translation)
            // const notTranslatedClutterCat3 = findEmtpyTranslation(localProjectData.value.content.clutterCat3.translation)
            // const notTranslatedClutterCat4 = findEmtpyTranslation(localProjectData.value.content.clutterCat4.translation)
            // if (rcda.length > 0 && !notTranslatedPages && !notTranslatedTestCat && !notTranslatedClutterCat1 && !notTranslatedClutterCat2 && !notTranslatedClutterCat3 && !notTranslatedClutterCat4) {
            //     translationAlert.value = false
            //     validationConfirmation.value = true

            // } else {
                
            //     translationAlert.value = true
            //     alertText.value += `There are missing translations. Please ensure all translations are done before validating the questionnaire`
            // }
            translationAlert.value = false
            validationConfirmation.value = true
            
        }

        const getVersions = () => {
            translationAlert.value = false
            fetchVersions({job_number:localProjectData.value.job_number})
        }

        const findEmtpyTranslation = (json) => {
            var trail = []
            var notTranslated = false

            const eachRecursive = (obj) => {
                for (var k in obj) {
                    trail.push(obj[k])
                    if (typeof obj[k] == "object" && obj[k] !== null) {
                        eachRecursive(obj[k]);
                        if(notTranslated) break
                    } else {
                        if (obj["name"] !== null) {
                            var lang_count = localProjectData.value.languages.length
                            for (var lang of localProjectData.value.languages){
                                if (!obj[lang.code] || obj[lang.code].trim() == "") {
                                    lang_count--
                                }
                            }
                            if (lang_count > 0 && lang_count < localProjectData.value.languages.length) {
                                notTranslated = true
                                break
                            }
                        }
                    }
                    trail.pop()
                }
            }
            eachRecursive(json)
            return notTranslated

        }

        onMounted(async () => {
            await fetchProject()
            fetchLanguages()
            if (router.currentRoute.params.new) isProjectInfoDialogOpen.value = true

        })

        watch(projectData,
        () => {
            localProjectData.value = normalizeProjectData(JSON.parse(JSON.stringify(projectData.value)))
            checkPipingVar(localProjectData.value)
            resetSection()
            if (isObjectEmpty(projectData.value.content)) isProjectInfoDialogOpen.value = true
            localProjectData.value.json.pages.length == 0 ? isFromScratch.value = true : isFromScratch.value = false
        })


        watch(pipingCheck,
        (newVal, oldVal) => {
            if(newVal.length > 0) {
                pipingVar.value = newVal[newVal.length-1]
                isPipingVarDialogOpen.value = true
            }
        })

        
        const calculateTextFieldRows = ( (e, text) => {
            console.log(e)
            const charsPerRow = 50;
            if (text == '' || text == undefined) return 1
            return Math.ceil(text.length / charsPerRow);
            }
        )

        watch(
            validationConfirmation,
            (newVal) => {
                if (!newVal) {
                    versionNotes.value = ""
                    if (vm.$refs.versionConfirmForm != undefined) vm.$refs.versionConfirmForm.resetValidation()
                }
            }
        )

        return {
            // local variables for the forms
            
            questionClick,
            isQuestionDrawerActive,
            question,
            updateQuestion,
            isQuestionTypeDialog,
            questionLabelList,
            
            questionsWithChoices,
            variableSuggestions,

            surveyTab,
            surveyTabItems,
            translationHeaders,
            textMapping,
            editTranslation,
            languageOptions,
            languageLoading,
            downloadTranslation,
            uploadTranslation,
            isUploadSelecting,
            onUploadClick,
            aiTranslation,
            isTranslationDialogOpen,
            isPaperQuestionnaireDialogOpen,
            deleteQuestionnaireDialogOpen,
            isProjectInfoDialogOpen,
            isQuotaDialogOpen,
            isNewSectionDialogOpen,
            exportLoading,
            translationLoading,
            resetQuestionNumber,
            deleteQuestionnaireLoading,
            isFromScratch,
            
            isDialogOpen,
            titleDialog,
            updateItemList,
            itemList,
            openQuestionType,
            indexPage,
            addElement,
            duplicateElement,
            deleteElement,
            getContentTooltip,

            localProjectData,
            projectSampleOverview,
            download,
            save,
            addNewSection,
            appendSection,
            deleteSection,
            newSection,
            resetNewSection,
            openSection,
            updateSection,
            sectionClick,
            deleteQuestionnaire,

            projectPermission,
            prgmPermission,

            //piping Var
            pipingVar,
            isPipingVarDialogOpen,
            updatePipe,
            calculateTextFieldRows,
            validationConfirmed,
            versionsItems,
            versionsLoading,
            getVersions,
            translationAlert,
            alertText,
            versionNotes,
            validationConfirmation,
            BuildQuestionnaire,

            icons: {
                mdiHeart,
                mdiShareVariant,
                mdiPencil,
                mdiTrashCanOutline,
                mdiPlus,
                mdiContentCopy,
                mdiTextBoxEditOutline,
                mdiSetSplit,
                mdiFormatAlignJustify,
                mdiDeleteOutline,
                mdiDotsHorizontal,
                mdiContentSaveOutline,
                mdiDownloadBoxOutline,
                mdiFolderOpenOutline,
                mdiInformationOutline,
                mdiFileWordOutline,
                mdiTranslate,
                mdiPlaylistPlus,
                mdiContentSaveCheckOutline,
                mdiLockOpenAlertOutline,
                mdiClipboardAlertOutline,
                mdiCartPercent
            },
            validators: {
                required,
                requiredArray,
                alphaDashValidator,
                integerValidator,
                billingRuleValidator
            }
        }
    },
}
</script>

<style lang="css" scoped>

.v-banner__text {
    background-color: #e85a00;
}
.step-cursor {
    cursor: pointer;
}

.card-cursor {
    cursor: grab;
}
.v-banner--single-line >>> div {
    max-height: 30px !important;
}

.alert {
    border-color:rgba(232, 90, 0, 0.5)
}
.regular {
    border-color:rgba(94, 86, 105, 0.14)
}

.v-tooltip__content >>> span { 
    white-space: pre-wrap; 
}

.name-row {
  background: #ddd;
  border-top: 1px solid black;
  }
  
  .group-name {
  background: #eee;
  }

  .choice-name {
    color: rgba(94, 86, 105, 0.68) !important;
    font-weight:bold
  }

    .to-update {
        border-left: 2px solid #f00
    }
    
</style>

<style lang="scss" scoped>



.app-customizer-toggler {
    position: fixed;
    top: 50%;
    transform: translateX(-50%);
    right: -22px;
    background: var(--v-warning-base);
  }
  
  
  .app-customizer {
    position: relative;
    z-index: 7;
  
  
    // Fixes Overlay is shown below SystemBar
    @at-root {
      .v-overlay {
        & + .v-application--wrap > .v-system-bar {
          z-index: 6 !important;
        }
      }
    }
  }


</style>
